section {
    &.nyheter {
        .container {

        }
    }
}
@media screen and ( max-width:414px ) {
  section {
      &.nyheter {
          .container {
              width: 100%;
              padding: 10px;
          }
      }
  }
}
