%login-form-links {

    color: $color-white;
    outline: 0;
    text-decoration: none;

}

%login-form-links-hover {

    color: $ljjk-red;
    box-shadow: none;

}

.login {

			background: $ljjk-black;
			background-repeat: no-repeat;
			background-attachment: fixed;
			background-position: center;
			font-size: .95rem;

			h1 {

				margin: 0;
				padding: 10px;
				text-align: center;

				a {

						 background-image: url("http://lidingojujutsu.se/wp-content/themes/lidingojujutsu/images/logga-01.jpg") !important;
						 background-size: 68px 120px !important;
						 width: 68px !important;
						 height: 122px !important;
						 text-indent: -9999px !important; /* login h1 a text moves/hiddes to the left */
             display: inline-block;
             margin: 0;
             padding: 0;
             outline: 0;
             color: $color-yellow;
             font-size: 1rem;
             font-weight: 400;
             line-height: 1.3;
             text-decoration: none;
             overflow: hidden;
             -webkit-margin-before: 0px;
             -webkit-margin-after: 0px;
             -webkit-margin-start: 0px;
             -webkit-margin-end: 0px;

				}
			}

			.message, #login_error {

					width:100%;
					text-align: center;
					border-left: none;
				  padding: 10px;
				  font-size: 1rem;
				  color: $color-white !important;
					border-radius: 5px;

			}

			.message { background: $color-green; }

			#login_error { background: $color-dark-red;	}

			form {

					background: $color-whitesmoke-darker;
					border: 3px solid $color-yellow;
					padding: 25px !important;

					label {

              color: $color-black;
              font-size: .95rem !important;
              width: 100%;

          }

					.user-pass-wrap {

							.wp-pwd {

									.wp-hide-pw {

										 color: $ljjk-black !important;
										 border-color: none !important;
										 vertical-align: middle;
										 min-height: 100%;
										 max-height: 100%;

										 &:focus {

													border-color: transparent !important;
													box-shadow: none !important;

										 }

									}
							}
			  	}

					p {

						 margin-bottom: 10px !important;
						 color: $ljjk-black;
						 float: left !important;
						 width: 100% !important;

						 &.submit {

									width: 100%;
									height: auto;
									text-align: center;
									margin: 0 !important;

									.button-primary {

												float: none;
												width: 100% !important;

												&:focus {

														box-shadow: none !important;
														background: $ljjk-black;
														border-color: transparent;

												}
									}
						 }

						 &.forgetmenot {

									width: 100%;
									text-align: center;
									margin: 10px 0 !important;

									label {

											 display: inline-block;
											 width: auto;
											 margin: 0 !important;

									}

									input[type=checkbox] {

											display: inline-block;
											margin: 0 5px 0 0 !important;
											min-height: 23px;
											min-width: 23px;
											color: $ljjk-black;
											border: 4px solid $ljjk-black;
											border-radius: 0;
											-webkit-appearance: none;
											-moz-appearance: none;
											appearance: none;
											box-shadow: none;
											-moz-box-shadow: none;
											-webkit-box-shadow: none;

											&:checked::before {

													 content: url(data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20viewBox%3D%270%200%2020%2020%27%3E%3Cpath%20d%3D%27M14.83%204.89l1.34.94-5.81%208.38H9.02L5.78%209.67l1.34-1.25%202.57%202.4z%27%20fill%3D%27%232B2B2B%27%2F%3E%3C%2Fsvg%3E);

													 @include for-tablet-portrait-up {

																width: 25px !important;
																height: 25px !important;

													}

											}
									}
							}

					}

			
			}

			#nav { display: none; }

			#backtoblog {

				 text-align: center;
				 margin: 15px 0 10px !important;
				 font-size: .9rem;

				 a {

                  @extend %login-form-links;

                  &:link, &:visited, &:active {

                      @extend %login-form-links;

                  }

                  &:hover, &:focus  {

                      @extend %login-form-links-hover;

                  }
          }

		 }

		 .privacy-policy-page-link {

						 display: block;
						 float: left;
						 width: 100%;
						 margin: 10px 0 !important;
						 font-size: .9rem;

						 a.privacy-policy-link {

								 @extend %login-form-links;

								 &:hover, &:focus {
									 @extend %login-form-links-hover;
								 }

						 }
		 }
	} // .login
