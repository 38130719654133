.page-content {

  .row {
     text-align: left;
     padding: 0 0 10px 0;
  }

    h1 {
        margin: 20px 0;
        width: 100%;
    }

    p {
       width: 100%;
    }
}
