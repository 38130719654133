footer {

      width: 100%;
      float: left;
      min-height: 50px;
      background: $ljjk-black;

      p {

      	  width: 100%;
      	  font-size: 15px;
      	  color: $color-white;
      	  margin: 5px 0;
      	  line-height: 1.2rem;
      		float: left;

          a {

              &, &:link, &:visited, &:active, &:focus {

                  font-size: 15px;
                  color: $color-white;

              }

              &:hover {

                  color: $ljjk-red;
                  text-decoration: none;

              }
          }
    	}

      h4 {

          color: $color-yellow;
          margin: 6px 0;
          float: left;
          width: 100%;

      }

      .social {

          margin: 10px 0 20px 0;
          text-align: center;
          float: left;
          width: 100%;

          span {

              font-size: 30px;
              float: none;
              margin: 10px 15px 10px 0;

          }

          a {

              &, &:link, &:visited, &:active, &:focus {

                  font-size: 30px;
                  color: $color-white;
                  float: none;
                  text-decoration: none;

              }

              &:hover {

                color: $ljjk-red;
                text-decoration: none;

              }
          }
      }

      .wd {

          a {

               &, &:link {

                   font-size: .9rem;

               }
          }
	    }

      .footer-extra-info {

          font-size: .75rem;
          width: 100%;
          float: left;
          color: $color-white;
          margin-top: 10px;

          p {

              font-size: .78rem;
              margin: 0;

              a {

                   &, &:link {

                       font-size: .78rem;

                   }
              }
          }
      }
}
