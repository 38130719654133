/*===WP-Comments (Start)===*/
	.comments-all-content {
	    position: relative;
	    color: rgba(0, 0, 0, 1);
	    font-size: 16px;
	    width: 100%;
	    max-width: 500px;
	    line-height: 16px;
	    margin:10px auto 10px auto;
	    text-align: left;
	    display:block;
	}
	.comment-content {margin:20px 0;}
	.inloggad-box {
		width:100%;
		display:block;
		margin:10px 0 15px 0;
		color:#91949d;
	}
	.inloggad-box a {color:#91949d;}
	.inloggad-box p {margin-bottom:10px;display:block;}
	.comment-box {
		width:100%;
		min-height:74px;
		display:block;
		margin:10px 0 15px 0;
	}
	.comment-box img {
		width:64px;
		height:64px;
		border-radius: 32px;
		display:block;
		margin:auto 10px;
		float:left;
	}
	.comment-text {
		width:70%;
		color:#ffffff;
		float:left;
		display: block;
		font-size: 80%;
	}
	.comment-text p {
		width:100%;
		margin-bottom:10px;
		display:block;
	}
	.cursiv {font-style: italic;}
	.content-aproval {
		border-radius: 3px;
		padding:5px;
		background:#730D15;
		color:#ffffff;
		display:block;
	}
	.comment-messages {
		color:#D8A043;
		font-family: "lato-regular", sans-serif;
		line-height: 1;
		font-size: 100%;
		margin:10px 0;
	}
	.comment-title-content{
		color:#ffffff;
		text-align:left;
		width:100%;
		padding:10px 0;
		border-bottom:1px solid #91949d;
		display:block;
	}
	.comment-title-box-big {float:left;width:60%;}
	.comment-title-box-small {float:left;width:40%;}
	.comment-title-box-big h3 {text-align: left;padding-bottom: 0;}
	.comment-title-box-small small {
		color:#91949d;
		float:right;
		text-align: right;
		line-height: 2;
	}
	.comment-allowed-tags {color:#91949d;margin:10px 0;}
/*===WP-Comments (Start)===*/
