.slider {

  	width: 100%;
  	height: 500px;
  	max-height: 500px;
  	overflow: hidden;
  	position: relative;
    background: $color-whitesmoke;

}
@media screen and ( max-width:1000px ) {

    .slider {
        height: auto;
    }

}
