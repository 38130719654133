.main {
  .container {

      min-height: calc( 100vh - 605px );
      padding: 40px 0;

      .row {

          text-align: left;

          h1 {

                width: 100%;
                text-align: center;

          }

          p {
              width: 100%;

          }
      }
  }
}

@media screen and (max-width:414px) {

    .main {

      .container {

          width: 100%;
          max-width: 100%;
          padding: 10px;

      }

    }
}
