@mixin for-iphone-xs {
  @media (max-width: 320px) { @content; }
}
@mixin for-iphone-small {
  @media (max-width: 375px) { @content; }
}
@mixin for-iphone {
  @media (max-width: 414px) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (max-width: 768px) { @content; }
}
@mixin for-desktop-up {
  @media (max-width: 1000px) { @content; }
}
@mixin for-big-desktop-up {
  @media (max-width: 1800px) { @content; }
}
