section {
    &.kontaktaoss {
        position: relative;

        .container {

            .contact-form-header {

                width: 100%;

            }

            .contact-form {

                  max-width: 500px;
                  margin: 0 auto

            }

        }
    }
}

.grecaptcha-badge {

    opacity: 1;
    position: absolute !important;
    bottom: initial !important;
    top: 20px !important;
    
}
