.to-pages {

    background: $color-grey-section-bg;

    .container {

        padding: 45px 30px;

        .row {
            .col-12 {

                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                align-items: center;

                img {

                    width: 95px;
                    height: auto;
                    padding: 0 20px 20px 20px;

                }

                p {

                    font-size: 1rem;

                }
                .button-wrapper {
                    display: flex;
                    flex-direction: column;
                    flex: 1 1 auto !important; /* IE11-fix */
                    justify-content: flex-end;
                }
            }
        }
    }
}
@media screen and ( max-width:768px ) {
  .to-pages {
      .container {
          .row {
              .col-12 {
                  padding: 15px;
              }
          }
      }
  }
}
@media screen and ( max-width:414px ) {

  .to-pages {
      .container {

          padding: 15px;

          .row {
              .col-12 {

                  padding: 15px 0;

                  img {

                      width: 110px;
                      padding: 15px;

                  }


              }
         }
     }
  }
}
