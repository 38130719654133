@media screen and (max-width:1000px) {
  table tbody tr td,
  table thead tr th { font-size: 0.8em; }

}
@media screen and ( max-width:768px ) {
  section.prices .col-12 { margin-bottom: 15px; }
  footer .col-12 { padding-bottom: 20px; }
  footer p, footer h4, footer .social { text-align: center; float: none; }
  footer .social a,
  footer .social a:active,
  footer .social a:link,
  footer .social a:visited,
  footer .social span  { float: none; }
  .page-numbers { margin-bottom: 10px; }
}
@media screen and (max-width:414px) {

  

    #wpadminbar { position: fixed; }
    #kontaktaoss .container, #prices .container { padding:20px 10px;}
    #kontaktaoss .submit { width: 50%; }
    article, article .article-content { width:100%; text-align: center; float:none; }
    article .thumbnail { float:none; }
    article h2, article .date { text-align: center; }
    table,
    table tbody,
    table thead,
    table thead tr { width:100%; display:block;}
    table {border:1px solid #cccccc;}
    .two-columns tbody tr td:first-child,
    .two-columns tbody tr td:last-child,
    .three-columns tbody tr td:first-child,
    .three-columns tbody tr td:last-child,
    table tbody tr td,
    table thead tr th {
        display:block;
        width:100%;
        text-align: center;
        padding:6px;
        font-size:0.8em;
        border:none;
    }
    table tbody tr { width:100%; display:block; border:none; }
    table tbody tr td li {text-align: left;}
    footer .social a,
    footer .social a:active,
    footer .social a:link,
    footer .social a:visited { float: none; }
    footer p, footer h4, footer .social { text-align: center; }
    footer .col-12 { padding-bottom: 20px; }


    .image-box { width: 100%;  margin:0 0 20px 0; }

    .table-blackbelt tr td { width: 100%; border: none; }
    .table-blackbelt tr td:first-of-type { width: 100%; }
    .table-blackbelt tr td ul li { text-align: center; }
    .table-blackbelt tr td figcaption {
    		font-size: 1rem;
      }


}
