@font-face {
		font-family: 'lato-hairline';
		font-style: normal;
		font-weight: 100;
		src: local("Lato Hairline"), local("Lato-Hairline"), url(../fonts/Lato-Hairline.woff) format("woff"), url(../fonts/Lato-Hairline.ttf) format("truetype");
}

@font-face {
		font-family: 'lato-light';
		font-style: normal;
		font-weight: 300;
		src: local("Lato Light"), local("Lato-Light"), url(../fonts/Lato-Light.woff) format("woff"), url(../fonts/Lato-Light.ttf) format("truetype");
}

@font-face {
		font-family: 'lato-regular';
		font-style: normal;
		font-weight: 400;
		src: local("Lato Regular"), local("Lato-Regular"), url(../fonts/Lato-Regular.woff) format("woff"), url(../fonts/Lato-Regular.svg#Lato) format("svg"), url(../fonts/Lato-Regular.ttf) format("truetype");
}

@font-face {
		font-family: 'lato-bold';
		font-style: normal;
		font-weight: 700;
		src: local("Lato Bold"), local("Lato-Bold"), url(../fonts/Lato-Bold.woff) format("woff"), url(../fonts/Lato-Bold.ttf) format("truetype");
}

@font-face {
		font-family: 'lato-black';
		font-style: normal;
		font-weight: 900;
		src: local("Lato Black"), local("Lato-Black"), url(../fonts/Lato-Black.woff) format("woff"), url(../fonts/Lato-Black.ttf) format("truetype");
}

@font-face {
		font-family: 'lato-hairline-italic';
		font-style: italic;
		font-weight: 100;
		src: local("Lato Hairline Italic"), local("Lato-HairlineItalic"), url(../fonts/Lato-Hairline-Italic.woff) format("woff"), url(../fonts/Lato-Hairline-Italic.ttf) format("truetype");
}

@font-face {
		font-family: 'lato-light-italic';
		font-style: italic;
		font-weight: 300;
		src: local("Lato Light Italic"), local("Lato-LightItalic"), url(../fonts/Lato-LightItalic.woff) format("woff"), url(../fonts/Lato-LightItalic.ttf) format("truetype");
}

@font-face {
		font-family: 'lato-italic';
		font-style: italic;
		font-weight: 400;
		src: local("Lato Italic"), local("Lato-Italic"), url(../fonts/Lato-Italic.woff) format("woff"), url(../fonts/Lato-Italic.ttf) format("truetype");
}

@font-face {
		font-family: 'lato-bold-italic';
		font-style: italic;
		font-weight: 700;
		src: local("Lato Bold Italic"), local("Lato-BoldItalic"), url(../fonts/Lato-Bold-Italic.woff) format("woff"), url(../fonts/Lato-Bold-Italic.ttf) format("truetype");
}

@font-face {
		font-family: 'lato-black-italic';
		font-style: italic;
		font-weight: 900;
		src: local("Lato Black Italic"), local("Lato-BlackItalic"), url(../fonts/Lato-Black-Italic.woff) format("woff"), url(../fonts/Lato-Black-Italic.ttf) format("truetype");
}

@font-face {
		font-family: 'icomoon';
		font-style: normal;
		font-weight: normal;
		src: url("../fonts/icomoon.eot?ewyj6g");
		src: url("../fonts/icomoon.eot?ewyj6g#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?ewyj6g") format("truetype"), url("../fonts/icomoon.woff?ewyj6g") format("woff"), url("../fonts/icomoon.svg?ewyj6g#icomoon") format("svg");
}

[class^='icon-'],
[class*=' icon-'] {
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		font-family: 'icomoon' !important;
		font-style: normal;
		font-variant: normal;
		font-weight: normal;
		line-height: 1;
		speak: none;
		text-transform: none;
}

.icon-alert:before {
		content: "\f02d";
}

.icon-alignment-align:before {
		content: "\f08a";
}

.icon-alignment-aligned-to:before {
		content: "\f08e";
}

.icon-alignment-unalign:before {
		content: "\f08b";
}

.icon-arrow-down:before {
		content: "\f03f";
}

.icon-arrow-left:before {
		content: "\f040";
}

.icon-arrow-right:before {
		content: "\f03e";
}

.icon-arrow-small-down:before {
		content: "\f0a0";
}

.icon-arrow-small-left:before {
		content: "\f0a1";
}

.icon-arrow-small-right:before {
		content: "\f071";
}

.icon-arrow-small-up:before {
		content: "\f09f";
}

.icon-arrow-up:before {
		content: "\f03d";
}

.icon-beer:before {
		content: "\f069";
}

.icon-book:before {
		content: "\f007";
}

.icon-bookmark:before {
		content: "\f07b";
}

.icon-briefcase:before {
		content: "\f0d3";
}

.icon-broadcast:before {
		content: "\f048";
}

.icon-browser:before {
		content: "\f0c5";
}

.icon-bug:before {
		content: "\f091";
}

.icon-calendar:before {
		content: "\f068";
}

.icon-check:before {
		content: "\f03a";
}

.icon-checklist:before {
		content: "\f076";
}

.icon-chevron-down:before {
		content: "\f0a3";
}

.icon-chevron-left:before {
		content: "\f0a4";
}

.icon-chevron-right:before {
		content: "\f078";
}

.icon-chevron-up:before {
		content: "\f0a2";
}

.icon-circle-slash:before {
		content: "\f084";
}

.icon-circuit-board:before {
		content: "\f0d6";
}

.icon-clippy:before {
		content: "\f035";
}

.icon-clock:before {
		content: "\f046";
}

.icon-cloud-download:before {
		content: "\f00b";
}

.icon-cloud-upload:before {
		content: "\f00c";
}

.icon-code:before {
		content: "\f05f";
}

.icon-color-mode:before {
		content: "\f065";
}

.icon-comment:before {
		content: "\f02b";
}

.icon-comment-discussion:before {
		content: "\f04f";
}

.icon-credit-card:before {
		content: "\f045";
}

.icon-dash:before {
		content: "\f0ca";
}

.icon-dashboard:before {
		content: "\f07d";
}

.icon-database:before {
		content: "\f096";
}

.icon-device-camera:before {
		content: "\f056";
}

.icon-device-camera-video:before {
		content: "\f057";
}

.icon-device-desktop:before {
		content: "\f27c";
}

.icon-device-mobile:before {
		content: "\f038";
}

.icon-diff:before {
		content: "\f04d";
}

.icon-diff-added:before {
		content: "\f06b";
}

.icon-diff-ignored:before {
		content: "\f099";
}

.icon-diff-modified:before {
		content: "\f06d";
}

.icon-diff-removed:before {
		content: "\f06c";
}

.icon-diff-renamed:before {
		content: "\f06e";
}

.icon-ellipsis:before {
		content: "\f09a";
}

.icon-eye:before {
		content: "\f04e";
}

.icon-file-binary:before {
		content: "\f094";
}

.icon-file-code:before {
		content: "\f010";
}

.icon-file-directory:before {
		content: "\f016";
}

.icon-file-media:before {
		content: "\f012";
}

.icon-file-pdf:before {
		content: "\f014";
}

.icon-file-submodule:before {
		content: "\f017";
}

.icon-file-symlink-directory:before {
		content: "\f0b1";
}

.icon-file-symlink-file:before {
		content: "\f0b0";
}

.icon-file-text:before {
		content: "\f011";
}

.icon-file-zip:before {
		content: "\f013";
}

.icon-flame:before {
		content: "\f0d2";
}

.icon-fold:before {
		content: "\f0cc";
}

.icon-gear:before {
		content: "\f02f";
}

.icon-gift:before {
		content: "\f042";
}

.icon-gist:before {
		content: "\f00e";
}

.icon-gist-secret:before {
		content: "\f08c";
}

.icon-git-branch:before {
		content: "\f020";
}

.icon-git-commit:before {
		content: "\f01f";
}

.icon-git-compare:before {
		content: "\f0ac";
}

.icon-git-merge:before {
		content: "\f023";
}

.icon-git-pull-request:before {
		content: "\f009";
}

.icon-globe:before {
		content: "\f0b6";
}

.icon-graph:before {
		content: "\f043";
}

.icon-heart:before {
		content: "\2665";
}

.icon-history:before {
		content: "\f07e";
}

.icon-home:before {
		content: "\f08d";
}

.icon-horizontal-rule:before {
		content: "\f070";
}

.icon-hourglass:before {
		content: "\f09e";
}

.icon-hubot:before {
		content: "\f09d";
}

.icon-inbox:before {
		content: "\f0cf";
}

.icon-info:before {
		content: "\f059";
}

.icon-issue-closed:before {
		content: "\f028";
}

.icon-issue-opened:before {
		content: "\f026";
}

.icon-issue-reopened:before {
		content: "\f027";
}

.icon-jersey:before {
		content: "\f019";
}

.icon-jump-down:before {
		content: "\f072";
}

.icon-jump-left:before {
		content: "\f0a5";
}

.icon-jump-right:before {
		content: "\f0a6";
}

.icon-jump-up:before {
		content: "\f073";
}

.icon-key:before {
		content: "\f049";
}

.icon-keyboard:before {
		content: "\f00d";
}

.icon-law:before {
		content: "\f0d8";
}

.icon-light-bulb:before {
		content: "\f000";
}

.icon-link:before {
		content: "\f05c";
}

.icon-link-external:before {
		content: "\f07f";
}

.icon-list-ordered:before {
		content: "\f062";
}

.icon-list-unordered:before {
		content: "\f061";
}

.icon-location:before {
		content: "\f060";
}

.icon-lock:before {
		content: "\f06a";
}

.icon-logo-github:before {
		content: "\f092";
}

.icon-mail:before {
		content: "\f03b";
}

.icon-mail-read:before {
		content: "\f03c";
}

.icon-mail-reply:before {
		content: "\f051";
}

.icon-mark-github:before {
		content: "\f00a";
}

.icon-markdown:before {
		content: "\f0c9";
}

.icon-megaphone:before {
		content: "\f077";
}

.icon-mention:before {
		content: "\f0be";
}

.icon-microscope:before {
		content: "\f089";
}

.icon-milestone:before {
		content: "\f075";
}

.icon-mirror:before {
		content: "\f024";
}

.icon-mortar-board:before {
		content: "\f0d7";
}

.icon-move-down:before {
		content: "\f0a8";
}

.icon-move-left:before {
		content: "\f074";
}

.icon-move-right:before {
		content: "\f0a9";
}

.icon-move-up:before {
		content: "\f0a7";
}

.icon-mute:before {
		content: "\f080";
}

.icon-no-newline:before {
		content: "\f09c";
}

.icon-octoface:before {
		content: "\f008";
}

.icon-organization:before {
		content: "\f037";
}

.icon-package:before {
		content: "\f0c4";
}

.icon-paintcan:before {
		content: "\f0d1";
}

.icon-pencil:before {
		content: "\f058";
}

.icon-person:before {
		content: "\f018";
}

.icon-pin:before {
		content: "\f041";
}

.icon-playback-fast-forward:before {
		content: "\f0bd";
}

.icon-playback-pause:before {
		content: "\f0bb";
}

.icon-playback-play:before {
		content: "\f0bf";
}

.icon-playback-rewind:before {
		content: "\f0bc";
}

.icon-plug:before {
		content: "\f0d4";
}

.icon-plus:before {
		content: "\f05d";
}

.icon-podium:before {
		content: "\f0af";
}

.icon-primitive-dot:before {
		content: "\f052";
}

.icon-primitive-square:before {
		content: "\f053";
}

.icon-pulse:before {
		content: "\f085";
}

.icon-puzzle:before {
		content: "\f0c0";
}

.icon-question:before {
		content: "\f02c";
}

.icon-quote:before {
		content: "\f063";
}

.icon-radio-tower:before {
		content: "\f030";
}

.icon-repo:before {
		content: "\f001";
}

.icon-repo-clone:before {
		content: "\f04c";
}

.icon-repo-force-push:before {
		content: "\f04a";
}

.icon-repo-forked:before {
		content: "\f002";
}

.icon-repo-pull:before {
		content: "\f006";
}

.icon-repo-push:before {
		content: "\f005";
}

.icon-rocket:before {
		content: "\f033";
}

.icon-rss:before {
		content: "\f034";
}

.icon-ruby:before {
		content: "\f047";
}

.icon-screen-full:before {
		content: "\f066";
}

.icon-screen-normal:before {
		content: "\f067";
}

.icon-search:before {
		content: "\f02e";
}

.icon-server:before {
		content: "\f097";
}

.icon-settings:before {
		content: "\f07c";
}

.icon-sign-in:before {
		content: "\f036";
}

.icon-sign-out:before {
		content: "\f032";
}

.icon-split:before {
		content: "\f0c6";
}

.icon-squirrel:before {
		content: "\f0b2";
}

.icon-star:before {
		content: "\f02a";
}

.icon-steps:before {
		content: "\f0c7";
}

.icon-stop:before {
		content: "\f08f";
}

.icon-sync:before {
		content: "\f087";
}

.icon-tag:before {
		content: "\f015";
}

.icon-telescope:before {
		content: "\f088";
}

.icon-terminal:before {
		content: "\f0c8";
}

.icon-three-bars:before {
		content: "\f05e";
}

.icon-tools:before {
		content: "\f031";
}

.icon-trashcan:before {
		content: "\f0d0";
}

.icon-triangle-down:before {
		content: "\f05b";
}

.icon-triangle-left:before {
		content: "\f044";
}

.icon-triangle-right:before {
		content: "\f05a";
}

.icon-triangle-up:before {
		content: "\f0aa";
}

.icon-unfold:before {
		content: "\f039";
}

.icon-unmute:before {
		content: "\f0ba";
}

.icon-versions:before {
		content: "\f064";
}

.icon-x:before {
		content: "\f081";
}

.icon-zap:before {
		content: "\26a1";
}

.icon-home4:before {
		content: "\e800";
}

.icon-home2:before {
		content: "\e801";
}

.icon-home3:before {
		content: "\e802";
}

.icon-office:before {
		content: "\e803";
}

.icon-newspaper:before {
		content: "\e804";
}

.icon-pencil3:before {
		content: "\e805";
}

.icon-pencil2:before {
		content: "\e806";
}

.icon-quill:before {
		content: "\e807";
}

.icon-pen:before {
		content: "\e808";
}

.icon-blog:before {
		content: "\e809";
}

.icon-eyedropper:before {
		content: "\e80a";
}

.icon-droplet:before {
		content: "\e80b";
}

.icon-paint-format:before {
		content: "\e80c";
}

.icon-image:before {
		content: "\e80d";
}

.icon-images:before {
		content: "\e80e";
}

.icon-camera:before {
		content: "\e80f";
}

.icon-headphones:before {
		content: "\e810";
}

.icon-music:before {
		content: "\e811";
}

.icon-play:before {
		content: "\e812";
}

.icon-film:before {
		content: "\e813";
}

.icon-video-camera:before {
		content: "\e814";
}

.icon-dice:before {
		content: "\e815";
}

.icon-pacman:before {
		content: "\e816";
}

.icon-spades:before {
		content: "\e817";
}

.icon-clubs:before {
		content: "\e818";
}

.icon-diamonds:before {
		content: "\e819";
}

.icon-bullhorn:before {
		content: "\e81a";
}

.icon-connection:before {
		content: "\e81b";
}

.icon-podcast:before {
		content: "\e81c";
}

.icon-feed:before {
		content: "\e81d";
}

.icon-mic:before {
		content: "\e81e";
}

.icon-book2:before {
		content: "\e81f";
}

.icon-books:before {
		content: "\e820";
}

.icon-library:before {
		content: "\e821";
}

.icon-file-text3:before {
		content: "\e822";
}

.icon-profile:before {
		content: "\e823";
}

.icon-file-empty:before {
		content: "\e824";
}

.icon-files-empty:before {
		content: "\e825";
}

.icon-file-text2:before {
		content: "\e826";
}

.icon-file-picture:before {
		content: "\e827";
}

.icon-file-music:before {
		content: "\e828";
}

.icon-file-play:before {
		content: "\e829";
}

.icon-file-video:before {
		content: "\e82a";
}

.icon-file-zip2:before {
		content: "\e82b";
}

.icon-copy:before {
		content: "\e82c";
}

.icon-paste:before {
		content: "\e82d";
}

.icon-stack:before {
		content: "\e82e";
}

.icon-folder:before {
		content: "\e82f";
}

.icon-folder-open:before {
		content: "\e830";
}

.icon-folder-plus:before {
		content: "\e831";
}

.icon-folder-minus:before {
		content: "\e832";
}

.icon-folder-download:before {
		content: "\e833";
}

.icon-folder-upload:before {
		content: "\e834";
}

.icon-price-tag:before {
		content: "\e835";
}

.icon-price-tags:before {
		content: "\e836";
}

.icon-barcode:before {
		content: "\e837";
}

.icon-qrcode:before {
		content: "\e838";
}

.icon-ticket:before {
		content: "\e839";
}

.icon-cart:before {
		content: "\e83a";
}

.icon-coin-dollar:before {
		content: "\e83b";
}

.icon-coin-euro:before {
		content: "\e83c";
}

.icon-coin-pound:before {
		content: "\e83d";
}

.icon-coin-yen:before {
		content: "\e83e";
}

.icon-credit-card2:before {
		content: "\e83f";
}

.icon-calculator:before {
		content: "\e840";
}

.icon-lifebuoy:before {
		content: "\e841";
}

.icon-phone:before {
		content: "\e842";
}

.icon-phone-hang-up:before {
		content: "\e843";
}

.icon-address-book:before {
		content: "\e844";
}

.icon-envelop:before {
		content: "\e845";
}

.icon-pushpin:before {
		content: "\e846";
}

.icon-location3:before {
		content: "\e847";
}

.icon-location2:before {
		content: "\e848";
}

.icon-compass:before {
		content: "\e849";
}

.icon-compass2:before {
		content: "\e84a";
}

.icon-map:before {
		content: "\e84b";
}

.icon-map2:before {
		content: "\e84c";
}

.icon-history2:before {
		content: "\e84d";
}

.icon-clock3:before {
		content: "\e84e";
}

.icon-clock2:before {
		content: "\e84f";
}

.icon-alarm:before {
		content: "\e850";
}

.icon-bell:before {
		content: "\e851";
}

.icon-stopwatch:before {
		content: "\e852";
}

.icon-calendar2:before {
		content: "\e853";
}

.icon-printer:before {
		content: "\e854";
}

.icon-keyboard2:before {
		content: "\e855";
}

.icon-display:before {
		content: "\e856";
}

.icon-laptop:before {
		content: "\e857";
}

.icon-mobile:before {
		content: "\e858";
}

.icon-mobile2:before {
		content: "\e859";
}

.icon-tablet:before {
		content: "\e85a";
}

.icon-tv:before {
		content: "\e85b";
}

.icon-drawer:before {
		content: "\e85c";
}

.icon-drawer2:before {
		content: "\e85d";
}

.icon-box-add:before {
		content: "\e85e";
}

.icon-box-remove:before {
		content: "\e85f";
}

.icon-download:before {
		content: "\e860";
}

.icon-upload:before {
		content: "\e861";
}

.icon-floppy-disk:before {
		content: "\e862";
}

.icon-drive:before {
		content: "\e863";
}

.icon-database2:before {
		content: "\e864";
}

.icon-undo:before {
		content: "\e865";
}

.icon-redo:before {
		content: "\e866";
}

.icon-undo2:before {
		content: "\e867";
}

.icon-redo2:before {
		content: "\e868";
}

.icon-forward:before {
		content: "\e869";
}

.icon-reply:before {
		content: "\e86a";
}

.icon-bubble:before {
		content: "\e86b";
}

.icon-bubbles:before {
		content: "\e86c";
}

.icon-bubbles2:before {
		content: "\e86d";
}

.icon-bubble2:before {
		content: "\e86e";
}

.icon-bubbles3:before {
		content: "\e86f";
}

.icon-bubbles4:before {
		content: "\e870";
}

.icon-user:before {
		content: "\e871";
}

.icon-users:before {
		content: "\e872";
}

.icon-user-plus:before {
		content: "\e873";
}

.icon-user-minus:before {
		content: "\e874";
}

.icon-user-check:before {
		content: "\e875";
}

.icon-user-tie:before {
		content: "\e876";
}

.icon-quotes-left:before {
		content: "\e877";
}

.icon-quotes-right:before {
		content: "\e878";
}

.icon-hour-glass:before {
		content: "\e879";
}

.icon-spinner:before {
		content: "\e87a";
}

.icon-spinner2:before {
		content: "\e87b";
}

.icon-spinner3:before {
		content: "\e87c";
}

.icon-spinner4:before {
		content: "\e87d";
}

.icon-spinner5:before {
		content: "\e87e";
}

.icon-spinner6:before {
		content: "\e87f";
}

.icon-spinner7:before {
		content: "\e880";
}

.icon-spinner8:before {
		content: "\e881";
}

.icon-spinner9:before {
		content: "\e882";
}

.icon-spinner10:before {
		content: "\e883";
}

.icon-spinner11:before {
		content: "\e884";
}

.icon-binoculars:before {
		content: "\e885";
}

.icon-search2:before {
		content: "\e886";
}

.icon-zoom-in:before {
		content: "\e887";
}

.icon-zoom-out:before {
		content: "\e888";
}

.icon-enlarge:before {
		content: "\e889";
}

.icon-shrink:before {
		content: "\e88a";
}

.icon-enlarge2:before {
		content: "\e88b";
}

.icon-shrink2:before {
		content: "\e88c";
}

.icon-key3:before {
		content: "\e88d";
}

.icon-key2:before {
		content: "\e88e";
}

.icon-lock2:before {
		content: "\e88f";
}

.icon-unlocked:before {
		content: "\e890";
}

.icon-wrench:before {
		content: "\e891";
}

.icon-equalizer:before {
		content: "\e892";
}

.icon-equalizer2:before {
		content: "\e893";
}

.icon-cog:before {
		content: "\e894";
}

.icon-cogs:before {
		content: "\e895";
}

.icon-hammer:before {
		content: "\e896";
}

.icon-magic-wand:before {
		content: "\e897";
}

.icon-aid-kit:before {
		content: "\e898";
}

.icon-bug2:before {
		content: "\e899";
}

.icon-pie-chart:before {
		content: "\e89a";
}

.icon-stats-dots:before {
		content: "\e89b";
}

.icon-stats-bars:before {
		content: "\e89c";
}

.icon-stats-bars2:before {
		content: "\e89d";
}

.icon-trophy:before {
		content: "\e89e";
}

.icon-gift2:before {
		content: "\e89f";
}

.icon-glass:before {
		content: "\e8a0";
}

.icon-glass2:before {
		content: "\e8a1";
}

.icon-mug:before {
		content: "\e8a2";
}

.icon-spoon-knife:before {
		content: "\e8a3";
}

.icon-leaf:before {
		content: "\e8a4";
}

.icon-rocket2:before {
		content: "\e8a5";
}

.icon-meter:before {
		content: "\e8a6";
}

.icon-meter2:before {
		content: "\e8a7";
}

.icon-hammer2:before {
		content: "\e8a8";
}

.icon-fire:before {
		content: "\e8a9";
}

.icon-lab:before {
		content: "\e8aa";
}

.icon-magnet:before {
		content: "\e8ab";
}

.icon-bin:before {
		content: "\e8ac";
}

.icon-bin2:before {
		content: "\e8ad";
}

.icon-briefcase2:before {
		content: "\e8ae";
}

.icon-airplane:before {
		content: "\e8af";
}

.icon-truck:before {
		content: "\e8b0";
}

.icon-road:before {
		content: "\e8b1";
}

.icon-accessibility:before {
		content: "\e8b2";
}

.icon-target:before {
		content: "\e8b3";
}

.icon-shield:before {
		content: "\e8b4";
}

.icon-power:before {
		content: "\e8b5";
}

.icon-switch:before {
		content: "\e8b6";
}

.icon-power-cord:before {
		content: "\e8b7";
}

.icon-clipboard:before {
		content: "\e8b8";
}

.icon-list-numbered:before {
		content: "\e8b9";
}

.icon-list:before {
		content: "\e8ba";
}

.icon-list2:before {
		content: "\e8bb";
}

.icon-tree:before {
		content: "\e8bc";
}

.icon-menu:before {
		content: "\e8bd";
}

.icon-menu2:before {
		content: "\e8be";
}

.icon-menu3:before {
		content: "\e8bf";
}

.icon-menu4:before {
		content: "\e8c0";
}

.icon-cloud:before {
		content: "\e8c1";
}

.icon-cloud-download2:before {
		content: "\e8c2";
}

.icon-cloud-upload2:before {
		content: "\e8c3";
}

.icon-cloud-check:before {
		content: "\e8c4";
}

.icon-download2:before {
		content: "\e8c5";
}

.icon-upload2:before {
		content: "\e8c6";
}

.icon-download3:before {
		content: "\e8c7";
}

.icon-upload3:before {
		content: "\e8c8";
}

.icon-sphere:before {
		content: "\e8c9";
}

.icon-earth:before {
		content: "\e8ca";
}

.icon-link2:before {
		content: "\e8cb";
}

.icon-flag:before {
		content: "\e8cc";
}

.icon-attachment:before {
		content: "\e8cd";
}

.icon-eye2:before {
		content: "\e8ce";
}

.icon-eye-plus:before {
		content: "\e8cf";
}

.icon-eye-minus:before {
		content: "\e8d0";
}

.icon-eye-blocked:before {
		content: "\e8d1";
}

.icon-bookmark2:before {
		content: "\e8d2";
}

.icon-bookmarks:before {
		content: "\e8d3";
}

.icon-sun:before {
		content: "\e8d4";
}

.icon-contrast:before {
		content: "\e8d5";
}

.icon-brightness-contrast:before {
		content: "\e8d6";
}

.icon-star-empty:before {
		content: "\e8d7";
}

.icon-star-half:before {
		content: "\e8d8";
}

.icon-star-full:before {
		content: "\e8d9";
}

.icon-heart2:before {
		content: "\e8da";
}

.icon-heart-broken:before {
		content: "\e8db";
}

.icon-man:before {
		content: "\e8dc";
}

.icon-woman:before {
		content: "\e8dd";
}

.icon-man-woman:before {
		content: "\e8de";
}

.icon-happy:before {
		content: "\e8df";
}

.icon-happy2:before {
		content: "\e8e0";
}

.icon-smile:before {
		content: "\e8e1";
}

.icon-smile2:before {
		content: "\e8e2";
}

.icon-tongue:before {
		content: "\e8e3";
}

.icon-tongue2:before {
		content: "\e8e4";
}

.icon-sad:before {
		content: "\e8e5";
}

.icon-sad2:before {
		content: "\e8e6";
}

.icon-wink:before {
		content: "\e8e7";
}

.icon-wink2:before {
		content: "\e8e8";
}

.icon-grin:before {
		content: "\e8e9";
}

.icon-grin2:before {
		content: "\e8ea";
}

.icon-cool:before {
		content: "\e8eb";
}

.icon-cool2:before {
		content: "\e8ec";
}

.icon-angry:before {
		content: "\e8ed";
}

.icon-angry2:before {
		content: "\e8ee";
}

.icon-evil:before {
		content: "\e8ef";
}

.icon-evil2:before {
		content: "\e8f0";
}

.icon-shocked:before {
		content: "\e8f1";
}

.icon-shocked2:before {
		content: "\e8f2";
}

.icon-baffled:before {
		content: "\e8f3";
}

.icon-baffled2:before {
		content: "\e8f4";
}

.icon-confused:before {
		content: "\e8f5";
}

.icon-confused2:before {
		content: "\e8f6";
}

.icon-neutral:before {
		content: "\e8f7";
}

.icon-neutral2:before {
		content: "\e8f8";
}

.icon-hipster:before {
		content: "\e8f9";
}

.icon-hipster2:before {
		content: "\e8fa";
}

.icon-wondering:before {
		content: "\e8fb";
}

.icon-wondering2:before {
		content: "\e8fc";
}

.icon-sleepy:before {
		content: "\e8fd";
}

.icon-sleepy2:before {
		content: "\e8fe";
}

.icon-frustrated:before {
		content: "\e8ff";
}

.icon-frustrated2:before {
		content: "\e900";
}

.icon-crying:before {
		content: "\e901";
}

.icon-crying2:before {
		content: "\e902";
}

.icon-point-up:before {
		content: "\e903";
}

.icon-point-right:before {
		content: "\e904";
}

.icon-point-down:before {
		content: "\e905";
}

.icon-point-left:before {
		content: "\e906";
}

.icon-warning:before {
		content: "\e907";
}

.icon-notification:before {
		content: "\e908";
}

.icon-question2:before {
		content: "\e909";
}

.icon-plus2:before {
		content: "\e90a";
}

.icon-minus:before {
		content: "\e90b";
}

.icon-info2:before {
		content: "\e90c";
}

.icon-cancel-circle:before {
		content: "\e90d";
}

.icon-blocked:before {
		content: "\e90e";
}

.icon-cross:before {
		content: "\e90f";
}

.icon-checkmark:before {
		content: "\e910";
}

.icon-checkmark2:before {
		content: "\e911";
}

.icon-spell-check:before {
		content: "\e912";
}

.icon-enter:before {
		content: "\e913";
}

.icon-exit:before {
		content: "\e914";
}

.icon-play2:before {
		content: "\e915";
}

.icon-pause:before {
		content: "\e916";
}

.icon-stop3:before {
		content: "\e917";
}

.icon-previous:before {
		content: "\e918";
}

.icon-next:before {
		content: "\e919";
}

.icon-backward:before {
		content: "\e91a";
}

.icon-forward2:before {
		content: "\e91b";
}

.icon-play3:before {
		content: "\e91c";
}

.icon-pause2:before {
		content: "\e91d";
}

.icon-stop2:before {
		content: "\e91e";
}

.icon-backward2:before {
		content: "\e91f";
}

.icon-forward3:before {
		content: "\e920";
}

.icon-first:before {
		content: "\e921";
}

.icon-last:before {
		content: "\e922";
}

.icon-previous2:before {
		content: "\e923";
}

.icon-next2:before {
		content: "\e924";
}

.icon-eject:before {
		content: "\e925";
}

.icon-volume-high:before {
		content: "\e926";
}

.icon-volume-medium:before {
		content: "\e927";
}

.icon-volume-low:before {
		content: "\e928";
}

.icon-volume-mute:before {
		content: "\e929";
}

.icon-volume-mute2:before {
		content: "\e92a";
}

.icon-volume-increase:before {
		content: "\e92b";
}

.icon-volume-decrease:before {
		content: "\e92c";
}

.icon-loop:before {
		content: "\e92d";
}

.icon-loop2:before {
		content: "\e92e";
}

.icon-infinite:before {
		content: "\e92f";
}

.icon-shuffle:before {
		content: "\e930";
}

.icon-arrow-up-left:before {
		content: "\e931";
}

.icon-arrow-up3:before {
		content: "\e932";
}

.icon-arrow-up-right:before {
		content: "\e933";
}

.icon-arrow-right3:before {
		content: "\e934";
}

.icon-arrow-down-right:before {
		content: "\e935";
}

.icon-arrow-down3:before {
		content: "\e936";
}

.icon-arrow-down-left:before {
		content: "\e937";
}

.icon-arrow-left3:before {
		content: "\e938";
}

.icon-arrow-up-left2:before {
		content: "\e939";
}

.icon-arrow-up2:before {
		content: "\e93a";
}

.icon-arrow-up-right2:before {
		content: "\e93b";
}

.icon-arrow-right2:before {
		content: "\e93c";
}

.icon-arrow-down-right2:before {
		content: "\e93d";
}

.icon-arrow-down2:before {
		content: "\e93e";
}

.icon-arrow-down-left2:before {
		content: "\e93f";
}

.icon-arrow-left2:before {
		content: "\e940";
}

.icon-circle-up:before {
		content: "\e941";
}

.icon-circle-right:before {
		content: "\e942";
}

.icon-circle-down:before {
		content: "\e943";
}

.icon-circle-left:before {
		content: "\e944";
}

.icon-tab:before {
		content: "\e945";
}

.icon-move-up2:before {
		content: "\e946";
}

.icon-move-down2:before {
		content: "\e947";
}

.icon-sort-alpha-asc:before {
		content: "\e948";
}

.icon-sort-alpha-desc:before {
		content: "\e949";
}

.icon-sort-numeric-asc:before {
		content: "\e94a";
}

.icon-sort-numberic-desc:before {
		content: "\e94b";
}

.icon-sort-amount-asc:before {
		content: "\e94c";
}

.icon-sort-amount-desc:before {
		content: "\e94d";
}

.icon-command:before {
		content: "\e94e";
}

.icon-shift:before {
		content: "\e94f";
}

.icon-ctrl:before {
		content: "\e950";
}

.icon-opt:before {
		content: "\e951";
}

.icon-checkbox-checked:before {
		content: "\e952";
}

.icon-checkbox-unchecked:before {
		content: "\e953";
}

.icon-radio-checked:before {
		content: "\e954";
}

.icon-radio-checked2:before {
		content: "\e955";
}

.icon-radio-unchecked:before {
		content: "\e956";
}

.icon-crop:before {
		content: "\e957";
}

.icon-make-group:before {
		content: "\e958";
}

.icon-ungroup:before {
		content: "\e959";
}

.icon-scissors:before {
		content: "\e95a";
}

.icon-filter:before {
		content: "\e95b";
}

.icon-font:before {
		content: "\e95c";
}

.icon-ligature:before {
		content: "\e95d";
}

.icon-ligature2:before {
		content: "\e95e";
}

.icon-text-height:before {
		content: "\e95f";
}

.icon-text-width:before {
		content: "\e960";
}

.icon-font-size:before {
		content: "\e961";
}

.icon-bold:before {
		content: "\e962";
}

.icon-underline:before {
		content: "\e963";
}

.icon-italic:before {
		content: "\e964";
}

.icon-strikethrough:before {
		content: "\e965";
}

.icon-omega:before {
		content: "\e966";
}

.icon-sigma:before {
		content: "\e967";
}

.icon-page-break:before {
		content: "\e968";
}

.icon-superscript:before {
		content: "\e969";
}

.icon-subscript:before {
		content: "\e96a";
}

.icon-superscript2:before {
		content: "\e96b";
}

.icon-subscript2:before {
		content: "\e96c";
}

.icon-text-color:before {
		content: "\e96d";
}

.icon-pagebreak:before {
		content: "\e96e";
}

.icon-clear-formatting:before {
		content: "\e96f";
}

.icon-table:before {
		content: "\e970";
}

.icon-table2:before {
		content: "\e971";
}

.icon-insert-template:before {
		content: "\e972";
}

.icon-pilcrow:before {
		content: "\e973";
}

.icon-ltr:before {
		content: "\e974";
}

.icon-rtl:before {
		content: "\e975";
}

.icon-section:before {
		content: "\e976";
}

.icon-paragraph-left:before {
		content: "\e977";
}

.icon-paragraph-center:before {
		content: "\e978";
}

.icon-paragraph-right:before {
		content: "\e979";
}

.icon-paragraph-justify:before {
		content: "\e97a";
}

.icon-indent-increase:before {
		content: "\e97b";
}

.icon-indent-decrease:before {
		content: "\e97c";
}

.icon-share:before {
		content: "\e97d";
}

.icon-new-tab:before {
		content: "\e97e";
}

.icon-embed:before {
		content: "\e97f";
}

.icon-embed2:before {
		content: "\e980";
}

.icon-terminal2:before {
		content: "\e981";
}

.icon-share2:before {
		content: "\e982";
}

.icon-mail5:before {
		content: "\e983";
}

.icon-mail2:before {
		content: "\e984";
}

.icon-mail3:before {
		content: "\e985";
}

.icon-mail4:before {
		content: "\e986";
}

.icon-google:before {
		content: "\e987";
}

.icon-google-plus:before {
		content: "\e988";
}

.icon-google-plus2:before {
		content: "\e989";
}

.icon-google-plus3:before {
		content: "\e98a";
}

.icon-google-drive:before {
		content: "\e98b";
}

.icon-facebook:before {
		content: "\e98c";
}

.icon-facebook2:before {
		content: "\e98d";
}

.icon-facebook3:before {
		content: "\e98e";
}

.icon-ello:before {
		content: "\e98f";
}

.icon-instagram:before {
		content: "\e990";
}

.icon-twitter:before {
		content: "\e991";
}

.icon-twitter2:before {
		content: "\e992";
}

.icon-twitter3:before {
		content: "\e993";
}

.icon-feed2:before {
		content: "\e994";
}

.icon-feed3:before {
		content: "\e995";
}

.icon-feed4:before {
		content: "\e996";
}

.icon-youtube:before {
		content: "\e997";
}

.icon-youtube2:before {
		content: "\e998";
}

.icon-youtube3:before {
		content: "\e999";
}

.icon-youtube4:before {
		content: "\e99a";
}

.icon-twitch:before {
		content: "\e99b";
}

.icon-vimeo:before {
		content: "\e99c";
}

.icon-vimeo2:before {
		content: "\e99d";
}

.icon-vimeo3:before {
		content: "\e99e";
}

.icon-lanyrd:before {
		content: "\e99f";
}

.icon-flickr:before {
		content: "\e9a0";
}

.icon-flickr2:before {
		content: "\e9a1";
}

.icon-flickr3:before {
		content: "\e9a2";
}

.icon-flickr4:before {
		content: "\e9a3";
}

.icon-picassa:before {
		content: "\e9a4";
}

.icon-picassa2:before {
		content: "\e9a5";
}

.icon-dribbble:before {
		content: "\e9a6";
}

.icon-dribbble2:before {
		content: "\e9a7";
}

.icon-dribbble3:before {
		content: "\e9a8";
}

.icon-forrst:before {
		content: "\e9a9";
}

.icon-forrst2:before {
		content: "\e9aa";
}

.icon-deviantart:before {
		content: "\e9ab";
}

.icon-deviantart2:before {
		content: "\e9ac";
}

.icon-steam:before {
		content: "\e9ad";
}

.icon-steam2:before {
		content: "\e9ae";
}

.icon-dropbox:before {
		content: "\e9af";
}

.icon-onedrive:before {
		content: "\e9b0";
}

.icon-github:before {
		content: "\e9b1";
}

.icon-github2:before {
		content: "\e9b2";
}

.icon-github3:before {
		content: "\e9b3";
}

.icon-github4:before {
		content: "\e9b4";
}

.icon-github5:before {
		content: "\e9b5";
}

.icon-wordpress:before {
		content: "\e9b6";
}

.icon-wordpress2:before {
		content: "\e9b7";
}

.icon-joomla:before {
		content: "\e9b8";
}

.icon-blogger:before {
		content: "\e9b9";
}

.icon-blogger2:before {
		content: "\e9ba";
}

.icon-tumblr:before {
		content: "\e9bb";
}

.icon-tumblr2:before {
		content: "\e9bc";
}

.icon-yahoo:before {
		content: "\e9bd";
}

.icon-tux:before {
		content: "\e9be";
}

.icon-apple:before {
		content: "\e9bf";
}

.icon-finder:before {
		content: "\e9c0";
}

.icon-android:before {
		content: "\e9c1";
}

.icon-windows:before {
		content: "\e9c2";
}

.icon-windows8:before {
		content: "\e9c3";
}

.icon-soundcloud:before {
		content: "\e9c4";
}

.icon-soundcloud2:before {
		content: "\e9c5";
}

.icon-skype:before {
		content: "\e9c6";
}

.icon-reddit:before {
		content: "\e9c7";
}

.icon-linkedin:before {
		content: "\e9c8";
}

.icon-linkedin2:before {
		content: "\e9c9";
}

.icon-lastfm:before {
		content: "\e9ca";
}

.icon-lastfm2:before {
		content: "\e9cb";
}

.icon-delicious:before {
		content: "\e9cc";
}

.icon-stumbleupon:before {
		content: "\e9cd";
}

.icon-stumbleupon2:before {
		content: "\e9ce";
}

.icon-stackoverflow:before {
		content: "\e9cf";
}

.icon-pinterest:before {
		content: "\e9d0";
}

.icon-pinterest2:before {
		content: "\e9d1";
}

.icon-xing:before {
		content: "\e9d2";
}

.icon-xing2:before {
		content: "\e9d3";
}

.icon-flattr:before {
		content: "\e9d4";
}

.icon-foursquare:before {
		content: "\e9d5";
}

.icon-paypal:before {
		content: "\e9d6";
}

.icon-paypal2:before {
		content: "\e9d7";
}

.icon-paypal3:before {
		content: "\e9d8";
}

.icon-yelp:before {
		content: "\e9d9";
}

.icon-file-pdf2:before {
		content: "\e9da";
}

.icon-file-openoffice:before {
		content: "\e9db";
}

.icon-file-word:before {
		content: "\e9dc";
}

.icon-file-excel:before {
		content: "\e9dd";
}

.icon-libreoffice:before {
		content: "\e9de";
}

.icon-html5:before {
		content: "\e9df";
}

.icon-html52:before {
		content: "\e9e0";
}

.icon-css3:before {
		content: "\e9e1";
}

.icon-git:before {
		content: "\e9e2";
}

.icon-svg:before {
		content: "\e9e3";
}

.icon-codepen:before {
		content: "\e9e4";
}

.icon-chrome:before {
		content: "\e9e5";
}

.icon-firefox:before {
		content: "\e9e6";
}

.icon-IE:before {
		content: "\e9e7";
}

.icon-opera:before {
		content: "\e9e8";
}

.icon-safari:before {
		content: "\e9e9";
}

.icon-IcoMoon:before {
		content: "\e9ea";
}

* {
		padding: 0;
		margin: 0;
		-ms-box-sizing: border-box;
		box-sizing: border-box;
}

body,
html {
		height: 100%;
}

body {
		font-family: 'lato-regular';
		font-weight: 400;
		font-size: 1rem;
		/* 16px */
		color: #2B2B2B;
		min-width: 320px;
		-webkit-text-size-adjust: 100%;
}

a, a:link, a:visited {
		color: #2B2B2B;
		text-decoration: none;
}

a:hover, a:active {
		color: #e3001a;
		text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
		font-family: 'lato-bold';
		color: #2B2B2B;
}

h1 {
		text-transform: uppercase;
		font-weight: normal;
		font-size: 1.4rem;
		margin-bottom: 15px;
}

h2 {
		font-size: 1.2rem;
}

h3 {
		font-size: 1.15rem;
}

h4 {
		font-size: 1.1rem;
}

.container {
		padding: 30px;
		width: auto;
		max-width: 1000px;
}

.row {
		margin: 0;
		text-align: center;
		width: 100%;
}

.expand-cont {
		min-height: 500px;
}

section {
		width: 100%;
		float: left;
		overflow: hidden;
}

section.valkommen, section.harfinnsvi {
		background: #ffffff;
}

section.nyheter, section.nyborjare, section.kontaktaoss {
		background: #e6e6e6;
}

/*===Hem+main-container===*/
.parallax {
		background: url(../images/i-bg-01.jpg);
		background-attachment: fixed;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
}

.parallax .container,
.parallax h1 {
		color: #ffffff;
		text-shadow: 1px 0px 3px #2B2B2B;
}

.section-icon {
		font-size: 2.6rem;
		margin-bottom: 15px;
		display: block;
		text-align: center;
		font-weight: normal;
		width: 100%;
}

.social-btns-div {
		width: 100%;
		float: left;
		min-height: 30px;
		margin: 10px 0 0 0;
}

.grey-bg {
		background: #ACADB2;
}

.white-bg {
		background-color: #ffffff;
}

.light-grey-bg {
		background: #E6E6E6;
}

.grey-text {
		color: #ACADB2;
}

.dark-grey-text {
		color: #666666;
}

.rad-varm-text {
		color: #AD2424;
}

.rad-text {
		color: #2B2B2B;
}

.gul-varm-text {
		color: #FFDB26;
}

.bla-text {
		color: #0B78B8;
}

.margin-to-div {
		margin: 10px 0;
}

/*=== Article (Start) ===*/
article {
		width: 100%;
		overflow: hidden;
		margin-bottom: 15px;
		padding: 10px;
		background: #ffffff;
}

article h2 {
		font-family: 'lato-bold';
		font-size: 1.2rem;
		color: #000000;
		margin: 10px 0;
		float: left;
		width: 100%;
		text-align: left;
}

.article-content {
		width: 70%;
		float: left;
}

.date {
		float: left;
		width: 100%;
		display: block;
		font-size: 0.85rem;
		color: #000000;
		margin: 0 0 20px 0;
}

.extract {
		width: 100%;
		color: #000000;
		float: left;
}

.extract p {
		font-size: 1rem;
		line-height: 1.5rem;
		font-family: 'lato-regular';
}

.buttons-wrapper {
		width: 100%;
		text-align: center;
		float: left;
		margin-top: 10px;
}

.buttons-wrapper p {
		width: 100%;
		float: left;
}

article .date ul {
		list-style: none;
		display: inline-block;
		color: #000000;
}

.thumbnail {
		float: left;
		position: relative;
		width: 170px;
		height: 170px;
		overflow: hidden;
		border: 0.3rem solid #000000;
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
}

.thumbnail img {
		position: absolute;
		left: 50%;
		top: 50%;
		height: 100%;
		width: auto;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
}

.thumbnail img.portrait {
		width: 100%;
		height: auto;
}

/*===Article (End)===*/
/*===Masonry-WP-Gallery (Start)===*/
figure {
		display: inline-block;
		width: 100%;
		height: auto;
		margin-bottom: 15px;
}

figure img {
		width: 100%;
		height: auto;
		border: 0.3rem solid #000000;
}

figcaption {
		font-family: "lato-regular";
		font-size: 0.9rem;
		line-height: 1.5rem;
		color: #ffffff;
		padding: 10px;
		background: #000000;
		width: 100%;
		float: left;
		text-align: center;
}

figcaption:after {
		content: '';
		display: block;
		clear: both;
}

.content-pint {
		margin: 20px auto;
		display: block;
}

/*===Masonry-WP-Gallery (End)===*/
.hamburger {
		display: none;
		float: right;
		width: 32px;
		height: 50px;
		padding: 10px 0;
		align-items: center;
		justify-content: center;
}

@media (max-width: 1000px) {
		.hamburger {
				display: flex;
		}
}

.hamburger:hover, .hamburger.is-active:hover {
		opacity: inherit;
}

.hamburger .hamburger-box {
		color: #fff;
		display: block;
		width: 100%;
}

.hamburger .hamburger-box .hamburger-inner {
		width: 100%;
		background-color: #fff;
}

.hamburger .hamburger-box .hamburger-inner::before, .hamburger .hamburger-box .hamburger-inner::after {
		width: 100%;
		max-width: 100%;
		background-color: #fff;
}

.header-push {
		float: left;
		height: 130px;
		width: 100%;
}

header {
		background: #2B2B2B;
		height: 130px;
		position: fixed;
		width: 100%;
		z-index: 9999;
		overflow: visible;
		user-select: none;
		-moz-user-select: none;
		/* Firefox */
		-ms-user-select: none;
		/* Internet Explorer */
		-khtml-user-select: none;
		/* KHTML browsers (e.g. Konqueror) */
		-webkit-user-select: none;
		/* Chrome, Safari, and Opera */
		-webkit-touch-callout: none;
		/* Disable Android and iOS callouts*/
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		/* Remove Gray Highlight When Tapping Links in some mobile browsers*/
		-webkit-tap-highlight-color: transparent;
		/* For some Androids */
}

header .line {
		width: 100%;
		height: 80px;
		border-bottom: 3px solid #FFEE00;
		position: absolute;
		top: 0;
}

header .container {
		padding: 0px;
		max-width: 100%;
		margin: 0 15px;
}

header .container .header-wrap {
		width: 1000px;
		min-height: 130px;
		position: relative;
		margin: 0 auto;
		background: #2B2B2B;
}

@media (max-width: 1000px) {
		header .container .header-wrap {
				width: 100%;
				height: 130px;
				min-height: 130px;
				max-height: 130px;
		}
}

@media (max-width: 414px) {
		header .container .header-wrap {
				height: 145px;
		}
}

header .container .header-wrap .logo-wrap {
		height: 159px;
		width: 90px;
		display: block;
		float: left;
		position: absolute;
		margin: 15px 0 0 0;
}

@media (max-width: 1000px) {
		header .container .header-wrap .logo-wrap {
				height: 100px;
				width: 57px;
				z-index: 10000;
		}
}

@media (max-width: 414px) {
		header .container .header-wrap .logo-wrap {
				width: 57px;
				height: 100px;
		}
}

header .container .header-wrap .logo-wrap img {
		width: 100%;
		height: 100%;
}

header .container .header-wrap .logo-text {
		height: 80px;
		max-height: 80px;
		overflow: hidden;
		float: left;
		width: 100%;
		text-align: right;
		padding: 0 0 10px 0;
		border-bottom: 3px solid #FFEE00;
}

header .container .header-wrap .logo-text h1, header .container .header-wrap .logo-text h2 {
		float: left;
		width: 100%;
		max-width: 100%;
		text-align: right;
		display: block;
		box-sizing: border-box;
}

header .container .header-wrap .logo-text h1 {
		margin: 10px 0 5px 0;
		color: #FFEE00;
		font-size: 24px;
}

@media (max-width: 414px) {
		header .container .header-wrap .logo-text h1 {
				font-size: 17px;
				margin: 15px 0 5px 0;
		}
}

header .container .header-wrap .logo-text h2 {
		margin: 0 0 10px 0;
		padding-right: 3px;
		font-family: 'lato-regular';
		font-style: italic;
		color: #fff;
		font-size: 17px;
}

@media (max-width: 414px) {
		header .container .header-wrap .logo-text h2 {
				font-size: 14px;
		}
}

header .container .header-wrap nav {
		height: 50px;
		float: right;
		display: block;
}

@media (max-width: 1000px) {
		header .container .header-wrap nav {
				position: fixed;
				background: #2B2B2B;
				width: 200px;
				height: 100%;
				height: -webkit-calc(100% - 64px);
				height: expression(100% - 64px);
				/* older version of IE */
				height: -moz-calc(100% - 64px);
				height: -o-calc(100% - 64px);
				height: calc(100% - 64px);
				z-index: 9999;
				min-width: 250px;
				top: 130px;
				right: 105%;
				margin: 0;
				padding-right: 0 !important;
				overflow: scroll;
				border-top: none;
				border-bottom: none;
		}
}

header .container .header-wrap nav ul {
		list-style: none;
		text-align: center;
		height: 50px;
		margin: 0;
}

@media (max-width: 1000px) {
		header .container .header-wrap nav ul > :first-child {
				border-top: 1px solid #FFEE00;
		}
}

header .container .header-wrap nav ul li {
		font-size: 13px;
		float: left;
		text-align: center;
		text-transform: uppercase;
		display: inline-block;
		height: 50px;
}

@media (max-width: 1000px) {
		header .container .header-wrap nav ul li {
				width: 100%;
				display: block;
				border-bottom: 1px solid #FFEE00;
				text-align: left;
		}
}

@media (max-width: 1000px) {
		header .container .header-wrap nav ul li.submenu {
				height: auto;
		}
}

header .container .header-wrap nav ul li a, header .container .header-wrap nav ul li a:visited, header .container .header-wrap nav ul li.submenu a, header .container .header-wrap nav ul li.submenu a:visited {
		font-family: "lato-regular";
		font-weight: normal;
		height: 50px;
		padding: 10px;
		color: #fff !important;
		display: block;
		text-decoration: none;
		line-height: 2rem;
		-webkit-transition: all 500ms ease;
		transition: all 500ms ease;
}

header .container .header-wrap nav ul li a:hover, header .container .header-wrap nav ul li.submenu a:hover {
		background: #FFEE00;
		color: #2B2B2B !important;
		text-decoration: none;
}

@media (max-width: 1000px) {
		header .container .header-wrap nav ul li a, header .container .header-wrap nav ul li.submenu a {
				display: block;
		}
		header .container .header-wrap nav ul li a, header .container .header-wrap nav ul li a:visited, header .container .header-wrap nav ul li a:active, header .container .header-wrap nav ul li.submenu a, header .container .header-wrap nav ul li.submenu a:visited, header .container .header-wrap nav ul li.submenu a:active {
				color: #fff;
		}
}

header .container .header-wrap nav ul li .caret {
		position: relative;
		top: 3px;
		margin-left: 10px;
		margin-right: 0;
}

@media (max-width: 1000px) {
		header .container .header-wrap nav ul li .caret {
				float: right;
		}
}

header .container .header-wrap nav ul li .children {
		display: none;
		position: absolute;
		height: 50px;
		width: 200px;
		z-index: 1000;
}

@media (max-width: 1000px) {
		header .container .header-wrap nav ul li .children {
				width: 100%;
				position: relative;
		}
		header .container .header-wrap nav ul li .children > :first-child {
				border-top: 1px solid #FFEE00;
		}
}

header .container .header-wrap nav ul li .children li {
		background: #2B2B2B;
		display: block;
		overflow: hidden;
		width: 100%;
		text-align: left;
}

header .container .header-wrap nav ul li .children li:not(:last-child) {
		border-bottom: 1px solid #FFEE00;
}

@media (max-width: 1000px) {
		header .container .header-wrap nav ul li .children li {
				background: #333333;
		}
}

header .container .header-wrap nav ul li .children li a {
		display: block;
		color: #fff;
		padding: 10px;
		height: 50px;
}

@media (max-width: 1000px) {
		header .container .header-wrap nav ul li .children li a {
				padding-left: 20px;
		}
}

/*===Form contents (Start)===
	  NORMALIZE BUTTON & INPUT - https://github.com/necolas/normalize.css
	  LAYOUT
	  INPUT, BUTTON & LABEL
	  ERROR
	  -------------------------
	  NORMALIZE BUTTON & INPUT
	  Known limitation: by default, Chrome and Safari on OS X allow very limited
	  styling of `select`, unless a `border` property is set.
	 */
button, input, optgroup, select, textarea {
		color: inherit;
		/* Correct color not being inherited. Known issue: affects color of disabled elements. */
		font: inherit;
		/* Correct font properties not being inherited. */
		margin: 0;
		/* Address margins set differently in Firefox 4+, Safari, and Chrome. */
}

button {
		overflow: visible;
}

/*Address `overflow` set to `hidden` in IE 8/9/10/11.*/
/**
	 * Address inconsistent `text-transform` inheritance for `button` and `select`.
	 * All other form control elements do not inherit `text-transform` values.
	 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
	 * Correct `select` style inheritance in Firefox.
	 */
button, select {
		text-transform: none;
}

/**
	 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
	 *    and `video` controls.
	 * 2. Correct inability to style clickable `input` types in iOS.
	 * 3. Improve usability and consistency of cursor style between image-type
	 *    `input` and others.
	 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
		-webkit-appearance: button;
		/* 2 */
		cursor: pointer;
		/* 3 */
}

/* Re-set default cursor for disabled elements. */
button[disabled],
html input[disabled] {
		cursor: default;
}

/* Remove inner padding and border in Firefox 4+.*/
button::-moz-focus-inner,
input::-moz-focus-inner {
		border: 0;
		padding: 0;
}

/* Address Firefox 4+ setting `line-height` on `input` using `!important` in
	 * the UA stylesheet. */
input {
		line-height: normal;
}

/*------------LAYOUT------------------------*/
/* [INPUT, BUTTON & LABEL] */
.btn-danger,
.login .button-primary, .login .button-primary:active, .login .button-primary:visited,
.btn-primary, .btn-primary:visited, .btn-primary:active,
.page-numbers, .page-numbers:visited, .page-numbers:active, .page-numbers:link {
		height: auto;
		font-family: 'lato-bold';
		font-weight: normal;
		font-size: 1rem;
		line-height: 1.5rem;
		text-transform: uppercase;
		text-align: center;
		text-decoration: none;
		border-radius: 0;
		border: none;
		padding: 10px 30px !important;
		display: inline-block !important;
		white-space: nowrap;
		vertical-align: middle;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-webkit-transition: all .2s ease-in-out;
		-o-transition: all .2s ease-in-out;
		transition: all .2s ease-in-out;
}

.btn-danger, .btn-danger:visited, .btn-danger:active {
		color: #fff;
}

.login .button-primary,
.login .button-primary:active,
.login .button-primary:focus,
.login .button-primary:visited,
.btn-primary,
.btn-primary:visited,
.btn-primary:active,
.page-numbers,
.page-numbers:visited,
.page-numbers:active,
.page-numbers:link {
		color: #fff !important;
		background-color: #2B2B2B;
		border-color: #2B2B2B;
}

.btn-primary, .btn-primary:visited, .btn-primary:active {
		min-width: 175px;
}

.login .button-primary:hover,
.btn-primary:hover, .page-numbers:hover {
		background-color: #E3001A;
		border-color: #E3001A;
		text-decoration: none;
}

.page-numbers.current {
		background-color: #E3001A;
		border-color: #E3001A;
}

.btn-yellow-red:link, .btn-yellow-red:visited {
		border-color: #FFEE00;
		background: #FFEE00;
		color: #2B2B2B;
		text-shadow: none;
}

.btn-yellow-red:hover, .btn-yellow-red:active {
		border-color: #E3001A;
		background: #E3001A;
		color: #fff;
}

.input {
		width: 100%;
		font-size: 1rem;
		display: inline-block;
		position: relative;
		color: #2B2B2B;
}

.labeled.input input {
		padding-right: 2.5em !important;
}

textarea,
input[type="text"],
.login input[type="text"],
input[type="password"],
.login input[type="password"] {
		width: 100%;
		margin: 0;
		padding: 0.65em 1em;
		font-size: 1rem;
		background: #fff;
		background-color: #fff;
		border: 0.3rem solid #2B2B2B;
		border-radius: 0;
		-webkit-border-radius: 0;
		outline: none;
		color: #2B2B2B;
		-moz-transition: none;
		-webkit-transition: none;
		-o-transition: color 0 ease-in;
		transition: none;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
		-webkit-appearance: none;
		-webkit-tap-highlight-color: transparent;
		/* for removing the highlight */
}

input[type="text"],
input[type="password"] {
		min-height: 49px !important;
		max-height: 49px !important;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus {
		color: #2B2B2B;
		border-color: #2B2B2B;
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
		-webkit-appearance: none;
		box-shadow: 0.3em 0em 0em 0em #FFEE00 inset;
}

textarea[readonly],
textarea[disabled],
input[readonly],
input[disabled] {
		cursor: not-allowed;
		background-color: #f7f7f7;
		color: #999;
}

label, .login label {
		width: 100%;
		font-size: 1rem !important;
		color: #2B2B2B;
		display: inline-block;
		vertical-align: middle;
		font-weight: normal;
}

p.comment-notes {
		padding: 10px 0;
		font-style: italic;
}

/*===Form contents (End)===*/
form {
		/* class field */
}

form .field {
		width: 100%;
		margin-bottom: 10px;
		display: block;
		float: left;
}

form .field label, form .field > label {
		width: 100%;
		display: block;
		position: relative;
		margin: 0 0 3px 0;
		color: #2B2B2B;
		font-weight: normal;
		text-align: left;
}

form .field .corner-label {
		position: absolute;
		display: inline-block;
		top: 0;
		right: 0;
		width: 2rem;
		height: 2rem;
		z-index: 0;
		margin: 0;
		padding: 2px 1px 0 0;
		text-align: center;
		vertical-align: middle;
		background-color: #2B2B2B;
		border-color: #2B2B2B;
		color: #fff;
		text-transform: uppercase;
		font-weight: normal;
		-webkit-transition: background 0.1s linear;
		transition: background 0.1s linear;
		overflow: hidden;
		background-color: transparent;
		-webkit-transition: color 0.2s ease;
		transition: color 0.2s ease;
}

form .field .corner-label:after {
		position: absolute;
		content: "";
		left: 0;
		top: 0;
		z-index: -1;
		width: 0em;
		height: 0em;
		border-top: 0rem solid transparent;
		border-right: 2rem solid transparent;
		border-bottom: 2rem solid transparent;
		border-left: 0rem solid transparent;
		border-right-color: inherit;
		-webkit-transition: border-color 0.2s ease;
		transition: border-color 0.2s ease;
}

form .field .corner-label i.icon {
		width: 100%;
		height: 100%;
		font-size: 1.25rem;
		font-style: normal;
		font-weight: normal;
		display: inline-block;
		line-height: 1;
		text-decoration: inherit;
		text-align: right;
		vertical-align: middle;
		padding: 4px 4px 0 0;
		speak: none;
		-webkit-font-smoothing: antialiased;
		-moz-font-smoothing: antialiased;
		font-smoothing: antialiased;
}

form .field .error-corner-label {
		border-color: #d95c5c;
		color: #fff;
}

form .field .error-color {
		color: #d95c5c !important;
		border-color: #d95c5c !important;
}

form .field .err-wrapper {
		display: none;
}

form .field .err-wrapper .error {
		position: relative;
		color: #fff;
		background: #d95c5c;
		border: 1px solid #d95c5c;
		font-size: 1rem;
		line-height: normal;
		text-align: center;
		padding: 10px;
		margin: 7px 0 0 0;
		border-radius: 5px;
}

form .field .err-wrapper .error::after {
		content: " ";
		position: absolute;
		bottom: 100%;
		left: 50%;
		margin-left: -10px;
		pointer-events: none;
		width: 0px;
		height: 0px;
		border-bottom: 7px solid #d95c5c;
		border-left: 7px solid transparent;
		border-right: 7px solid transparent;
}

form .field .input-field {
		width: 100%;
		font-size: 1em;
		display: block;
		position: relative;
		color: #2B2B2B;
}

form .field .labeled input {
		padding-right: 2.5em !important;
}

form .label-extra-info {
		font-size: 1rem;
		color: #2B2B2B;
		display: block;
}

form .form-img-loading {
		display: none;
}

form .feedback {
		display: none;
		text-align: center;
		width: 100%;
		float: left;
		padding: 15px;
		border-radius: 5px;
		margin: 0 0 10px 0;
		min-height: 30px;
		background-color: #00ba4f;
		color: #fff;
		font-size: 0.875em;
}

form .some-errors, form .tips {
		display: none;
		width: 100%;
		float: left;
		padding: 15px;
		margin: 0 0 15px 0;
		background: #FFFFCC;
		border: 1px solid #faebcc;
		border-radius: 5px;
}

form .some-errors span, form .tips span {
		width: 30px;
		font-size: 2rem;
		margin-right: 10px;
		display: inline-block;
		vertical-align: middle;
}

form .some-errors p, form .tips p {
		display: inline-block;
		width: calc( 100% - 45px);
		vertical-align: middle;
		text-align: left;
		margin: 0;
		font-size: 1rem;
}

@media screen and (max-width: 414px) {
		section.nyheter .container {
				width: 100%;
				padding: 10px;
		}
}

.valkommen .container .icon-dojo {
		width: 90px;
		height: 90px;
		max-height: 90px;
		max-width: 90px;
		padding: 20px;
		margin: 0 auto;
		display: block;
}

.valkommen .container .row {
		text-align: left;
}

.valkommen .container .row.section-row-header {
		margin: 0 0 25px 0;
		text-align: center;
}

.valkommen .container .row.section-row-header h1 {
		width: 100%;
}

.valkommen .container .row .col-12 {
		padding: 0;
}

.valkommen .container .row .col-12:first-of-type {
		padding: 0 25px 0 0;
		border-right: 1px solid #dfe0e4;
}

.valkommen .container .row .col-12:last-of-type {
		padding: 0 0 0 25px;
}

.valkommen .container .row .content-col {
		display: flex;
		flex-direction: column;
		justify-content: center;
		justify-content: flex-start;
}

.valkommen .container .row .content-col h2 {
		width: 100%;
		margin: 20px 0;
		font-size: 1.1rem;
		text-transform: uppercase;
		text-align: left;
}

.valkommen .container .row .content-col h3 {
		font-size: 1.05rem;
}

.valkommen .container .row .current-col .current-col-content {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
}

.valkommen .container .row .current-col .current-col-content h2 {
		width: 100%;
		font-size: 1.1rem;
		padding: 20px 0;
		text-transform: uppercase;
		width: 100%;
		text-align: left;
		margin: 0;
}

.valkommen .container .row .current-col .current-col-content h2 span {
		margin: 0 0 0 10px;
}

.valkommen .container .row .current-col .current-col-content .current-notice-wrapper a {
		color: #2B2B2B;
		display: block;
		padding: 0px 0;
		border-bottom-width: 1px;
		border-bottom-style: solid;
		border-bottom-color: #d0d1d5;
}

.valkommen .container .row .current-col .current-col-content .current-notice-wrapper a:hover .current-notice-item::after {
		transform: translate(5px, 0);
		color: #2B2B2B;
}

.valkommen .container .row .current-col .current-col-content .current-notice-wrapper a:last-of-type {
		border: none;
}

.valkommen .container .row .current-col .current-col-content .current-notice-wrapper .current-notice-item {
		width: 100%;
		padding: 7px 30px 10px 15px;
		position: relative;
		transition: all .2s ease-out;
}

.valkommen .container .row .current-col .current-col-content .current-notice-wrapper .current-notice-item:hover {
		background: #F5F5F5;
}

.valkommen .container .row .current-col .current-col-content .current-notice-wrapper .current-notice-item::after {
		content: "\f078";
		font-family: 'icomoon';
		font-size: 1.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		right: 7px;
		top: 0;
		width: 20px;
		height: 100%;
		color: #d0d1d5;
		transition: transform .3s ease-out;
}

.valkommen .container .row .current-col .current-col-content .current-notice-wrapper .current-notice-item h3 {
		font-size: 1.1rem;
		margin: 3px 0;
}

.valkommen .container .row .current-col .current-col-content .current-notice-wrapper .current-notice-item h3 span {
		margin: 0 10px 0 0;
		color: #e3001a;
}

.valkommen .container .row .current-col .current-col-content .current-notice-wrapper .current-notice-item p {
		margin: 0;
}

.valkommen .container .row .current-col .current-col-content .current-notice-wrapper .current-notice-item .current-notice-item-datum {
		font-size: .8rem;
		padding: 5px 0;
}

@media screen and (max-width: 768px) {
		.valkommen .container .row .col-12:first-of-type {
				padding: 0;
				border-right: none;
		}
		.valkommen .container .row .col-12:last-of-type {
				padding: 0;
		}
		.valkommen .container .row .content-col {
				text-align: center;
		}
		.valkommen .container .row .content-col h2 {
				text-align: center;
		}
		.valkommen .container .row .current-col .current-col-content h2 {
				text-align: center;
		}
}

@media screen and (max-width: 414px) {
		.valkommen .container {
				padding: 25px 15px;
		}
}

.main .container {
		min-height: calc( 100vh - 605px);
		padding: 40px 0;
}

.main .container .row {
		text-align: left;
}

.main .container .row h1 {
		width: 100%;
		text-align: center;
}

.main .container .row p {
		width: 100%;
}

@media screen and (max-width: 414px) {
		.main .container {
				width: 100%;
				max-width: 100%;
				padding: 10px;
		}
}

.page-content .row {
		text-align: left;
		padding: 0 0 10px 0;
}

.page-content h1 {
		margin: 20px 0;
		width: 100%;
}

.page-content p {
		width: 100%;
}

.prices .container .rad-text {
		color: #fff;
}

.prices .container .row {
		min-height: 180px;
		margin-bottom: 20px;
}

.prices .container .row .col-12 {
		min-height: 180px;
}

.prices .container .row .col-12 h2 {
		display: block;
		font-size: 1rem;
		line-height: 1rem;
		color: #FFEE00;
		background: #2B2B2B;
		padding: 12px 0 6px 0;
		margin-bottom: 0;
		text-transform: uppercase;
}

.prices .container .row .col-12 .price {
		font-size: 1.3rem;
		font-family: lato-bold;
		vertical-align: middle;
}

.prices .container .row .col-12 .price-small {
		font-size: 1.1rem;
		font-family: lato-bold;
		vertical-align: middle;
}

.prices .container .row .col-12 .title {
		line-height: 1.3rem;
		vertical-align: middle;
}

.prices .container .row .col-12 .table-wrapper {
		display: table;
		height: 180px;
		color: #2B2B2B;
		background: #e6e6e6;
		border: 0.3rem solid #2B2B2B;
		width: 100%;
		text-shadow: none;
}

.prices .container .row .col-12 .table-wrapper .table-content {
		display: table-cell;
		vertical-align: middle !important;
}

@media screen and (max-width: 768px) {
		.prices {
				background-attachment: initial;
		}
}

.to-pages {
		background: #e6e6e6;
}

.to-pages .container {
		padding: 45px 30px;
}

.to-pages .container .row .col-12 {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		align-items: center;
}

.to-pages .container .row .col-12 img {
		width: 95px;
		height: auto;
		padding: 0 20px 20px 20px;
}

.to-pages .container .row .col-12 p {
		font-size: 1rem;
}

.to-pages .container .row .col-12 .button-wrapper {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto !important;
		/* IE11-fix */
		justify-content: flex-end;
}

@media screen and (max-width: 768px) {
		.to-pages .container .row .col-12 {
				padding: 15px;
		}
}

@media screen and (max-width: 414px) {
		.to-pages .container {
				padding: 15px;
		}
		.to-pages .container .row .col-12 {
				padding: 15px 0;
		}
		.to-pages .container .row .col-12 img {
				width: 110px;
				padding: 15px;
		}
}

footer {
		width: 100%;
		float: left;
		min-height: 50px;
		background: #2B2B2B;
}

footer p {
		width: 100%;
		font-size: 15px;
		color: #fff;
		margin: 5px 0;
		line-height: 1.2rem;
		float: left;
}

footer p a, footer p a:link, footer p a:visited, footer p a:active, footer p a:focus {
		font-size: 15px;
		color: #fff;
}

footer p a:hover {
		color: #e3001a;
		text-decoration: none;
}

footer h4 {
		color: #FFEE00;
		margin: 6px 0;
		float: left;
		width: 100%;
}

footer .social {
		margin: 10px 0 20px 0;
		text-align: center;
		float: left;
		width: 100%;
}

footer .social span {
		font-size: 30px;
		float: none;
		margin: 10px 15px 10px 0;
}

footer .social a, footer .social a:link, footer .social a:visited, footer .social a:active, footer .social a:focus {
		font-size: 30px;
		color: #fff;
		float: none;
		text-decoration: none;
}

footer .social a:hover {
		color: #e3001a;
		text-decoration: none;
}

footer .wd a, footer .wd a:link {
		font-size: .9rem;
}

footer .footer-extra-info {
		font-size: .75rem;
		width: 100%;
		float: left;
		color: #fff;
		margin-top: 10px;
}

footer .footer-extra-info p {
		font-size: .78rem;
		margin: 0;
}

footer .footer-extra-info p a, footer .footer-extra-info p a:link {
		font-size: .78rem;
}

@media screen and (max-width: 1000px) {
		table tbody tr td,
		table thead tr th {
				font-size: 0.8em;
		}
}

@media screen and (max-width: 768px) {
		section.prices .col-12 {
				margin-bottom: 15px;
		}
		footer .col-12 {
				padding-bottom: 20px;
		}
		footer p, footer h4, footer .social {
				text-align: center;
				float: none;
		}
		footer .social a,
		footer .social a:active,
		footer .social a:link,
		footer .social a:visited,
		footer .social span {
				float: none;
		}
		.page-numbers {
				margin-bottom: 10px;
		}
}

@media screen and (max-width: 414px) {
		#wpadminbar {
				position: fixed;
		}
		#kontaktaoss .container, #prices .container {
				padding: 20px 10px;
		}
		#kontaktaoss .submit {
				width: 50%;
		}
		article, article .article-content {
				width: 100%;
				text-align: center;
				float: none;
		}
		article .thumbnail {
				float: none;
		}
		article h2, article .date {
				text-align: center;
		}
		table,
		table tbody,
		table thead,
		table thead tr {
				width: 100%;
				display: block;
		}
		table {
				border: 1px solid #cccccc;
		}
		.two-columns tbody tr td:first-child,
		.two-columns tbody tr td:last-child,
		.three-columns tbody tr td:first-child,
		.three-columns tbody tr td:last-child,
		table tbody tr td,
		table thead tr th {
				display: block;
				width: 100%;
				text-align: center;
				padding: 6px;
				font-size: 0.8em;
				border: none;
		}
		table tbody tr {
				width: 100%;
				display: block;
				border: none;
		}
		table tbody tr td li {
				text-align: left;
		}
		footer .social a,
		footer .social a:active,
		footer .social a:link,
		footer .social a:visited {
				float: none;
		}
		footer p, footer h4, footer .social {
				text-align: center;
		}
		footer .col-12 {
				padding-bottom: 20px;
		}
		.image-box {
				width: 100%;
				margin: 0 0 20px 0;
		}
		.table-blackbelt tr td {
				width: 100%;
				border: none;
		}
		.table-blackbelt tr td:first-of-type {
				width: 100%;
		}
		.table-blackbelt tr td ul li {
				text-align: center;
		}
		.table-blackbelt tr td figcaption {
				font-size: 1rem;
		}
}

.slider {
		width: 100%;
		height: 500px;
		max-height: 500px;
		overflow: hidden;
		position: relative;
		background: #F5F5F5;
}

@media screen and (max-width: 1000px) {
		.slider {
				height: auto;
		}
}

/*===WP-Comments (Start)===*/
.comments-all-content {
		position: relative;
		color: black;
		font-size: 16px;
		width: 100%;
		max-width: 500px;
		line-height: 16px;
		margin: 10px auto 10px auto;
		text-align: left;
		display: block;
}

.comment-content {
		margin: 20px 0;
}

.inloggad-box {
		width: 100%;
		display: block;
		margin: 10px 0 15px 0;
		color: #91949d;
}

.inloggad-box a {
		color: #91949d;
}

.inloggad-box p {
		margin-bottom: 10px;
		display: block;
}

.comment-box {
		width: 100%;
		min-height: 74px;
		display: block;
		margin: 10px 0 15px 0;
}

.comment-box img {
		width: 64px;
		height: 64px;
		border-radius: 32px;
		display: block;
		margin: auto 10px;
		float: left;
}

.comment-text {
		width: 70%;
		color: #ffffff;
		float: left;
		display: block;
		font-size: 80%;
}

.comment-text p {
		width: 100%;
		margin-bottom: 10px;
		display: block;
}

.cursiv {
		font-style: italic;
}

.content-aproval {
		border-radius: 3px;
		padding: 5px;
		background: #730D15;
		color: #ffffff;
		display: block;
}

.comment-messages {
		color: #D8A043;
		font-family: "lato-regular", sans-serif;
		line-height: 1;
		font-size: 100%;
		margin: 10px 0;
}

.comment-title-content {
		color: #ffffff;
		text-align: left;
		width: 100%;
		padding: 10px 0;
		border-bottom: 1px solid #91949d;
		display: block;
}

.comment-title-box-big {
		float: left;
		width: 60%;
}

.comment-title-box-small {
		float: left;
		width: 40%;
}

.comment-title-box-big h3 {
		text-align: left;
		padding-bottom: 0;
}

.comment-title-box-small small {
		color: #91949d;
		float: right;
		text-align: right;
		line-height: 2;
}

.comment-allowed-tags {
		color: #91949d;
		margin: 10px 0;
}

/*===WP-Comments (Start)===*/
.login #backtoblog a, .login #backtoblog a:link, .login #backtoblog a:visited, .login #backtoblog a:active, .login .privacy-policy-page-link a.privacy-policy-link {
		color: #fff;
		outline: 0;
		text-decoration: none;
}

.login #backtoblog a:hover, .login #backtoblog a:focus, .login .privacy-policy-page-link a.privacy-policy-link:hover, .login .privacy-policy-page-link a.privacy-policy-link:focus {
		color: #e3001a;
		box-shadow: none;
}

.login {
		background: #2B2B2B;
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-position: center;
		font-size: .95rem;
}

.login h1 {
		margin: 0;
		padding: 10px;
		text-align: center;
}

.login h1 a {
		background-image: url("http://lidingojujutsu.se/wp-content/themes/lidingojujutsu/images/logga-01.jpg") !important;
		background-size: 68px 120px !important;
		width: 68px !important;
		height: 122px !important;
		text-indent: -9999px !important;
		/* login h1 a text moves/hiddes to the left */
		display: inline-block;
		margin: 0;
		padding: 0;
		outline: 0;
		color: #FFEE00;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.3;
		text-decoration: none;
		overflow: hidden;
		-webkit-margin-before: 0px;
		-webkit-margin-after: 0px;
		-webkit-margin-start: 0px;
		-webkit-margin-end: 0px;
}

.login .message, .login #login_error {
		width: 100%;
		text-align: center;
		border-left: none;
		padding: 10px;
		font-size: 1rem;
		color: #fff !important;
		border-radius: 5px;
}

.login .message {
		background: #00ba4f;
}

.login #login_error {
		background: #a94442;
}

.login form {
		background: #F0F0F0;
		border: 3px solid #FFEE00;
		padding: 25px !important;
}

.login form label {
		color: #000;
		font-size: .95rem !important;
		width: 100%;
}

.login form .user-pass-wrap .wp-pwd .wp-hide-pw {
		color: #2B2B2B !important;
		border-color: none !important;
		vertical-align: middle;
		min-height: 100%;
		max-height: 100%;
}

.login form .user-pass-wrap .wp-pwd .wp-hide-pw:focus {
		border-color: transparent !important;
		box-shadow: none !important;
}

.login form p {
		margin-bottom: 10px !important;
		color: #2B2B2B;
		float: left !important;
		width: 100% !important;
}

.login form p.submit {
		width: 100%;
		height: auto;
		text-align: center;
		margin: 0 !important;
}

.login form p.submit .button-primary {
		float: none;
		width: 100% !important;
}

.login form p.submit .button-primary:focus {
		box-shadow: none !important;
		background: #2B2B2B;
		border-color: transparent;
}

.login form p.forgetmenot {
		width: 100%;
		text-align: center;
		margin: 10px 0 !important;
}

.login form p.forgetmenot label {
		display: inline-block;
		width: auto;
		margin: 0 !important;
}

.login form p.forgetmenot input[type=checkbox] {
		display: inline-block;
		margin: 0 5px 0 0 !important;
		min-height: 23px;
		min-width: 23px;
		color: #2B2B2B;
		border: 4px solid #2B2B2B;
		border-radius: 0;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		box-shadow: none;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
}

.login form p.forgetmenot input[type=checkbox]:checked::before {
		content: url(data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20viewBox%3D%270%200%2020%2020%27%3E%3Cpath%20d%3D%27M14.83%204.89l1.34.94-5.81%208.38H9.02L5.78%209.67l1.34-1.25%202.57%202.4z%27%20fill%3D%27%232B2B2B%27%2F%3E%3C%2Fsvg%3E);
}

@media (max-width: 768px) {
		.login form p.forgetmenot input[type=checkbox]:checked::before {
				width: 25px !important;
				height: 25px !important;
		}
}

.login #nav {
		display: none;
}

.login #backtoblog {
		text-align: center;
		margin: 15px 0 10px !important;
		font-size: .9rem;
}

.login .privacy-policy-page-link {
		display: block;
		float: left;
		width: 100%;
		margin: 10px 0 !important;
		font-size: .9rem;
}

.team-section .col {
		justify-content: center;
		align-items: center;
		display: flex;
		text-align: center;
}

.team-section .col figure {
		max-width: 200px;
		min-width: 200px;
}

.team-section .col figure img {
		border-radius: 50%;
		border: unset;
		width: 190px;
		max-width: 190px;
		min-width: 190px;
}

.team-section .col figure figcaption {
		margin: 10px 0 0 0;
		color: #2B2B2B;
		background: none;
}

.team-section .col figure figcaption h5 {
		font-size: 17px;
}

.team-section .col figure figcaption h6 {
		font-size: 14px;
}

.team-section .col figure figcaption p {
		font-size: 14px;
		margin: 0 0 5px 0;
		line-height: normal;
}

section.kontaktaoss {
		position: relative;
}

section.kontaktaoss .container .contact-form-header {
		width: 100%;
}

section.kontaktaoss .container .contact-form {
		max-width: 500px;
		margin: 0 auto;
}

.grecaptcha-badge {
		opacity: 1;
		position: absolute !important;
		bottom: initial !important;
		top: 20px !important;
}

.go-up {
		width: 50px;
		height: 50px;
		background: rgba(0, 0, 0, 0.5);
		cursor: pointer;
		position: fixed;
		bottom: 30px;
		right: 15px;
		border-radius: 25px;
		border: 2px solid #ffffff;
		z-index: 10000;
		display: none;
		overflow: hidden;
		text-align: center;
}

.go-up::before {
		font-size: 30px;
		font-family: 'icomoon';
		content: "\e932";
		line-height: 1.4;
}

a#goup, a#goup:link, a#goup:visited, a#goup:active {
		color: #ffffff;
}

a#goup:hover {
		color: #e3001a;
}

.table-blackbelt {
		width: 100%;
		border: 1px solid #ccc;
		border-spacing: 0px;
		border-collapse: separate;
}

.table-blackbelt tr td:first-of-type {
		width: 200px;
}

.table-blackbelt tr td p {
		text-align: center;
		font-size: 14px;
}

.table-blackbelt tr td ul {
		margin: 0;
		padding: 0;
		list-style-position: inside;
}

.table-blackbelt tr td ul li {
		font-size: 14px;
}

.table-blackbelt tr td figure {
		width: 120px;
		height: auto;
		margin: 0 auto;
		display: block;
}

.table-blackbelt tr td figure img {
		width: 100%;
		border: 3px solid #000;
		margin-bottom: 10px;
}

.table-blackbelt tr td figure figcaption {
		font-size: .8rem;
		font-weight: bold;
		background: none;
		color: #2B2B2B;
		float: none;
		padding: 0;
}

.cc-btn, .cc-dismiss {
		text-transform: uppercase;
}

.cc-revoke, .cc-window {
		font-family: 'lato-regular';
}

.row-news {
		margin: 0 0 20px 0;
}

.row-news .col-12 {
		display: flex;
		justify-content: center;
		padding: 12px 12px 0 0 !important;
}

.post-news {
		width: 300px;
		padding: 0;
		background: initial;
		height: 100%;
		margin: 0;
}

.post-news a {
		color: #2B2B2B;
}

.post-news:hover .post-news-content-wrapper .post-news-thumbnail .thumbnail-wrapper {
		opacity: 0.8;
		transform: scale(1.02);
}

.post-news:hover .post-news-content-wrapper .post-news-content .post-news-content-details,
.post-news:hover .post-news-content-wrapper .post-news-content h1,
.post-news:hover .post-news-content-wrapper .post-news-content .post-news-content-excerpt {
		opacity: 0.7;
}

.post-news .post-news-content-wrapper {
		display: flex;
		flex-direction: column;
		height: 100%;
}

.post-news .post-news-content-wrapper .post-news-thumbnail {
		height: 200px;
		width: 100%;
		overflow: hidden;
		border: 1px solid #dfe0e4;
		border-color: #e5e6e9 #dfe0e4 #d0d1d5;
}

.post-news .post-news-content-wrapper .post-news-thumbnail .thumbnail-wrapper {
		overflow: hidden;
		width: 100%;
		height: 100%;
		z-index: 0;
		opacity: 1;
		line-height: 0;
		transform: scale(1);
		transition: all .2s ease .1s;
}

.post-news .post-news-content-wrapper .post-news-thumbnail .thumbnail-wrapper img {
		width: 100%;
		height: 100%;
		object-fit: cover;
}

.post-news .post-news-content-wrapper .post-news-content {
		position: relative;
		z-index: 1000;
		margin: -40px 0 0 12px;
		background: white;
		padding: 10px;
		border-width: 1px;
		border-style: solid;
		border-color: #e5e6e9 #dfe0e4 #d0d1d5 #dfe0e4;
		display: flex;
		flex-direction: column;
		flex: 1 1 auto !important;
		/* IE11-fix */
}

.post-news .post-news-content-wrapper .post-news-content h1 {
		font-size: 1.1rem;
		text-transform: none;
		text-align: left;
		margin: 5px 0 10px 0;
}

.post-news .post-news-content-wrapper .post-news-content .post-news-content-details {
		font-size: .8rem;
		text-align: left;
		margin: 0;
		padding: 5px 0;
		width: auto;
		display: flex;
		align-items: stretch;
		flex-direction: row;
}

.post-news .post-news-content-wrapper .post-news-content .post-news-content-details span {
		margin: 0 3px 0 0;
		line-height: 1.1rem;
}

.post-news .post-news-content-wrapper .post-news-content .post-news-content-excerpt {
		font-size: .9rem;
		text-align: left;
}

.post-news .post-news-content-wrapper .post-news-content .post-news-content-excerpt p {
		margin: 0;
}

@media screen and (max-width: 414px) {
		.row-news {
				margin: 0 0 20px 0;
		}
		.row-news .col-12 {
				padding: 12px 0 0 0 !important;
		}
		.post-news {
				width: 95%;
		}
}

#map {
		width: 100%;
		height: 500px;
}
