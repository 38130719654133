* {

		padding: 0;
		margin: 0;
	  -ms-box-sizing: border-box;
	  box-sizing: border-box;

}

body,
html {

		height: 100%;

}

body {

	font-family: 'lato-regular';
	font-weight: 400;
	font-size: 1rem; /* 16px */
	color: $ljjk-black;
	min-width: 320px;
	-webkit-text-size-adjust: 100%;

}

a {

		&, &:link, &:visited {

				color: $ljjk-black;
				text-decoration: none;

		}

		&:hover, &:active {

				color: $ljjk-red;
				text-decoration: none; }

}

h1, h2, h3, h4, h5, h6 {

		font-family: 'lato-bold';
		color: $ljjk-black;

}

h1 {

		text-transform: uppercase;
		font-weight: normal;
		font-size: 1.4rem;
		margin-bottom: 15px;

}

h2 { font-size: 1.2rem; }
h3 { font-size: 1.15rem; }
h4 { font-size: 1.1rem; }

.container {

		padding: 30px;
		width: auto;
		max-width: 1000px;

}

.row {

		margin: 0;
		text-align: center;
		width: 100%;

}

.expand-cont { min-height: 500px; }



section { width:100%; float:left; overflow: hidden; }
section.valkommen, section.harfinnsvi { background: #ffffff; }
section.nyheter, section.nyborjare, section.kontaktaoss { background: #e6e6e6; }
/*===Hem+main-container===*/

.parallax {
	background: url(../images/i-bg-01.jpg);
	background-attachment: fixed;
	background-position: center;
  background-repeat: no-repeat;
	background-size: cover;
}
.parallax .container,
.parallax h1 {
	   color: #ffffff;
		 text-shadow: 1px 0px 3px $ljjk-black;
}
.section-icon {
	font-size: 2.6rem;
	margin-bottom: 15px;
	display: block;
	text-align: center;
	font-weight: normal;
	width: 100%;
}
.social-btns-div {
  width: 100%;
  float: left;
  min-height: 30px;
  margin: 10px 0 0 0;
}
.grey-bg { background: #ACADB2;}
.white-bg { background-color: #ffffff;}
.light-grey-bg { background: #E6E6E6;}
.grey-text { color: #ACADB2; }
.dark-grey-text { color:#666666;}
.rad-varm-text { color:#AD2424;}
.rad-text { color: $ljjk-black; }
.gul-varm-text { color:#FFDB26;}
.bla-text { color:#0B78B8;}
.margin-to-div { margin:10px 0;}
/*=== Article (Start) ===*/
article {
	width: 100%;
	overflow: hidden;
	margin-bottom: 15px;
	padding: 10px;
	background: #ffffff;
}
    article h2 {
    	font-family: 'lato-bold';
    	font-size: 1.2rem;
    	color: #000000;
    	margin: 10px 0;
    	float: left;
    	width: 100%;
    	text-align: left;
    }
    .article-content { width:70%; float:left; }
    .date  {

        float:left;
        width: 100%;
        display: block;
        font-size: 0.85rem;
        color: #000000;
        margin: 0 0 20px 0;

    }
    .extract { width:100%; color:#000000; float:left; }
    .extract p { font-size: 1rem; line-height: 1.5rem; font-family: 'lato-regular';}
    .buttons-wrapper { width:100%; text-align: center; float:left; margin-top:10px; }
		.buttons-wrapper p { width: 100%; float:left; }
    article .date ul { list-style: none; display: inline-block; color:#000000; }
		.thumbnail {
				float: left;
		    position: relative;
		    width: 170px;
		    height: 170px;
		    overflow: hidden;
		    border: 0.3rem solid #000000;
		    display: inline-block;
		    vertical-align: middle;
		    margin-right: 10px;
	  }
  .thumbnail img {
	    position: absolute;
	    left: 50%;
	    top: 50%;
	    height: 100%;
	    width: auto;
	    -webkit-transform: translate(-50%,-50%);
	        -ms-transform: translate(-50%,-50%);
	            transform: translate(-50%,-50%);
  }
  .thumbnail img.portrait { width: 100%; height: auto; }
/*===Article (End)===*/

/*===Masonry-WP-Gallery (Start)===*/
	figure {
		display: inline-block;
		width: 100%;
		height: auto;
		margin-bottom: 15px;
	}
	figure img { width:100%; height: auto; border: 0.3rem solid #000000; }
	figcaption {
		font-family: "lato-regular";
		font-size: 0.9rem;
		line-height: 1.5rem;
		color: #ffffff;
		padding: 10px;
		background: #000000;
		width: 100%;
		float: left;
		text-align: center;
  }
  figcaption:after { content:''; display: block; clear: both; }
	.content-pint { margin:20px auto; display: block; }
/*===Masonry-WP-Gallery (End)===*/
