.hamburger {

		display: none;
		float: right;
		width: 32px;
		height: 50px;
		padding: 10px 0;
		align-items: center;
		justify-content: center;

		@include for-desktop-up {

				display: flex;

		}

		&:hover, &.is-active:hover {

				opacity: inherit;

		}

		.hamburger-box {

			color: $color-white;
			display: block;
			width: 100%;

			.hamburger-inner {

					width: 100%;
					background-color: $color-white;

					&::before, &::after {

							width: 100%;
							max-width: 100%;
							background-color: $color-white;

					}
			}
	}

}

.header-push {

		float:left;
		height: 130px;
		width: 100%;

}

header {

		background: $ljjk-black;
		height: 130px;
		position: fixed;
		width: 100%;
		z-index: 9999;
		overflow: visible;
		user-select: none;
		-moz-user-select: none; /* Firefox */
	  -ms-user-select: none; /* Internet Explorer */
	  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
	  -webkit-user-select: none; /* Chrome, Safari, and Opera */
	  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
	  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Remove Gray Highlight When Tapping Links in some mobile browsers*/
		-webkit-tap-highlight-color: transparent; /* For some Androids */

		.line {

				width: 100%;
				height: 80px;
				border-bottom: 3px solid $color-yellow;
				position: absolute;
				top: 0;


		}

	  .container {

				padding: 0px;
				max-width: 100%;
				margin: 0 15px;

				.header-wrap {

						width: 1000px;
						min-height: 130px;
						position: relative;
						margin:0 auto;
				    background: $ljjk-black;

						@include for-desktop-up {

								width: 100%;
								height: 130px;
								min-height: 130px;
								max-height: 130px;

						}

						@include for-iphone {

								height: 145px;

						}

						.logo-wrap {

									height: 159px;
									width: 90px;
									display: block;
							    float: left;
									position: absolute;
									margin: 15px 0 0 0;

									@include for-desktop-up {

												height: 100px;
												width: 57px;
												z-index: 10000;

									}

									@include for-iphone {

												width: 57px;
												height: 100px;

									}

									img {

											width: 100%;
											height: 100%;

									}
						}

						.logo-text {

						  	height: 80px;
						  	max-height: 80px;
						  	overflow: hidden;
						  	float: left;
						  	width: 100%;
						  	text-align: right;
								padding: 0 0 10px 0;
								border-bottom: 3px solid $color-yellow;

								h1, h2 {

								  	float: left;
								  	width: 100%;
										max-width: 100%;
								  	text-align: right;
										display: block;
										box-sizing: border-box;

							  }

								h1 {

										margin: 10px 0 5px 0;
										color: $color-yellow;
										font-size: 24px;

										@include for-iphone {

												font-size: 17px;
												margin: 15px 0 5px 0;

										}

							  }

							  h2 {

										margin: 0 0 10px 0;
										padding-right: 3px;
										font-family: 'lato-regular';
										font-style: italic;
										color: $color-white;
										font-size: 17px;

										@include for-iphone {

												font-size: 14px;

										}

								}
					  }

						nav {

								height: 50px;
								float: right;
								display: block;

								@include for-desktop-up {

										position: fixed;
										background: $ljjk-black;
										width: 200px;
										height: 100%;
										height: -webkit-calc(100% - 64px);
										height: expression(100% - 64px); /* older version of IE */
										height: -moz-calc(100% - 64px);
										height: -o-calc(100% - 64px);
										height: calc(100% - 64px);
										z-index: 9999;
										min-width: 250px;
										top: 130px;
										right: 105%;
										margin: 0;
										padding-right: 0 !important;
										overflow: scroll;
										border-top: none;
										border-bottom: none;

								}

								ul {

										list-style: none;
										text-align: center;
										height: 50px;
										margin: 0;

										@include for-desktop-up {

												& > :first-child { border-top: 1px solid $color-yellow;	}

										}

										li {

												font-size: 13px;
												float: left;
												text-align: center;
												text-transform: uppercase;
												display: inline-block;
												height: 50px;

												@include for-desktop-up {

														width: 100%;
														display: block;
														border-bottom: 1px solid $color-yellow;
														text-align: left;

												}

												&.submenu {

														@include for-desktop-up {

																	height: auto;

														}

												}

												a, &.submenu a {

														&, &:visited {

																font-family: "lato-regular";
																font-weight: normal;
																height: 50px;
																padding: 10px;
																color: $color-white !important;
																display: block;
																text-decoration: none;
																line-height: 2rem;
																-webkit-transition: all 500ms ease;
																transition: all 500ms ease;

													  }

														&:hover {

																background: $color-yellow;
																color: $ljjk-black !important;
																text-decoration: none;

														}

														@include for-desktop-up {

																	display: block;

																	&, &:visited, &:active { color: $color-white; }

														}

												}

												.caret {

														position: relative;
														top: 3px;
														margin-left: 10px;
														margin-right: 0;

														@include for-desktop-up {

																float: right;

														}

												}

												.children {

															display: none;
															position: absolute;
															height: 50px;
															width: 200px;
															z-index: 1000;

															@include for-desktop-up {

																	width: 100%;
																	position: relative;

																	& > :first-child { border-top: 1px solid $color-yellow; }

															}

															li {

																	background: $ljjk-black;
																	display: block;
																	overflow: hidden;
																	width: 100%;
																	text-align: left;

																	&:not(:last-child) { border-bottom: 1px solid $color-yellow; }

																	@include for-desktop-up {

																			 background: $color-grey-submenu;

																	}

																	a {

																			display: block;
																			color: $color-white;
																			padding: 10px;
																			height: 50px;

																			@include for-desktop-up {

																						padding-left: 20px;

																			}

																	}
															}
												}
										}
								}
						}
				}
		}
}
