.go-up {

    width: 50px;
    height: 50px;
    background: rgba(0,0,0,0.5);
    cursor: pointer;
    position: fixed;
    bottom: 30px;
    right: 15px;
    border-radius: 25px;
    border: 2px solid #ffffff;
    z-index: 10000;
    display: none;
    overflow: hidden;
    text-align: center;

    &::before {

        font-size: 30px;
        font-family: 'icomoon';
        content: "\e932";
        line-height: 1.4;

    }

}
a#goup, a#goup:link, a#goup:visited, a#goup:active  { color: #ffffff }
a#goup:hover { color: $ljjk-red; }
