.valkommen {
    .container {

        .icon-dojo {

            width: 90px;
            height: 90px;
            max-height: 90px;
            max-width: 90px;
            padding: 20px;
            margin: 0 auto;
            display: block;


        }
        .row {

            text-align: left;

            &.section-row-header {

                margin: 0 0 25px 0;
                text-align: center;
                h1 { width: 100%; }

            }

            .col-12 {

                  padding: 0;
                  &:first-of-type {
                      padding: 0 25px 0 0;
                      border-right: 1px solid $color-border-right;
                  }
                  &:last-of-type {
                      padding: 0 0 0 25px;
                  }
            }

            .content-col {

                display: flex;
                flex-direction: column;
                justify-content: center;
                justify-content: flex-start;

                h2 {

                    width: 100%;
                    margin: 20px 0;
                    font-size: 1.1rem;
                    text-transform: uppercase;
                    text-align: left;
                }

                h3 {
                    font-size: 1.05rem;
                }
            }

            .current-col {

                .current-col-content {

                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: column;

                  h2 {

                      width: 100%;
                      font-size: 1.1rem;
                      padding: 20px 0;
                      text-transform: uppercase;
                      width: 100%;
                      text-align: left;
                      margin: 0;

                      span {
                          margin: 0 0 0 10px;
                      }

                  }

                  .current-notice-wrapper {

                      a {

                          color: $ljjk-black;
                          display: block;
                          padding: 0px 0;
                          border-bottom-width: 1px;
                          border-bottom-style: solid;
                          border-bottom-color: $color-border-bottom;

                          &:hover .current-notice-item::after {

                              transform: translate( 5px, 0 );
                              color: $ljjk-black;

                          }

                          &:last-of-type { border: none; }

                      }

                     .current-notice-item {

                         width: 100%;
                         padding: 7px 30px 10px 15px;
                         position: relative;
                         transition: all .2s ease-out;

                         &:hover {
                             background: $color-whitesmoke;
                         }

                         &::after {

                             content: "\f078";
                             font-family: 'icomoon';
                             font-size: 1.5rem;
                             display: flex;
                             align-items: center;
                             justify-content: center;
                             position: absolute;
                             right: 7px;
                             top: 0;
                             width: 20px;
                             height: 100%;
                             color: $color-border-bottom;
                             transition: transform .3s ease-out;

                         }

                         h3 {
                             font-size: 1.1rem;
                             margin: 3px 0;

                             span {

                                margin: 0 10px 0 0;
                                color: $ljjk-red;
                             }
                         }

                         p {
                             margin: 0;
                         }

                         .current-notice-item-datum {
                             font-size: .8rem;
                             padding: 5px 0;
                         }

                     }
                  }
                }
            }
        }
    }
}

@media screen and (max-width:768px) {

  .valkommen {
      .container {



          .row {
              .col-12 {
                    &:first-of-type {
                        padding: 0;
                        border-right: none;
                    }
                    &:last-of-type {
                        padding: 0;
                    }
              }
              .content-col {
                  text-align: center;

                  h2 {
                      text-align: center;
                  }
              }
              .current-col {
                  .current-col-content {
                      h2 {
                          text-align: center;
                      }
                  }
              }
          }
      }
  }
}

@media screen and (max-width: 414px) {

  .valkommen {
      .container {
          padding: 25px 15px;
      }
  }

}
