/*===Form contents (Start)===
	  NORMALIZE BUTTON & INPUT - https://github.com/necolas/normalize.css
	  LAYOUT
	  INPUT, BUTTON & LABEL
	  ERROR
	  -------------------------
	  NORMALIZE BUTTON & INPUT
	  Known limitation: by default, Chrome and Safari on OS X allow very limited
	  styling of `select`, unless a `border` property is set.
	 */
	button, input, optgroup, select, textarea {
	    color: inherit; /* Correct color not being inherited. Known issue: affects color of disabled elements. */
	    font: inherit; 	/* Correct font properties not being inherited. */
	    margin: 0; 		/* Address margins set differently in Firefox 4+, Safari, and Chrome. */
	}
	button {overflow: visible;} /*Address `overflow` set to `hidden` in IE 8/9/10/11.*/
	/**
	 * Address inconsistent `text-transform` inheritance for `button` and `select`.
	 * All other form control elements do not inherit `text-transform` values.
	 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
	 * Correct `select` style inheritance in Firefox.
	 */
	button, select {text-transform: none;}
	/**
	 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
	 *    and `video` controls.
	 * 2. Correct inability to style clickable `input` types in iOS.
	 * 3. Improve usability and consistency of cursor style between image-type
	 *    `input` and others.
	 */

	button,
	html input[type="button"], /* 1 */
	input[type="reset"],
	input[type="submit"] {
	    -webkit-appearance: button; /* 2 */
	    cursor: pointer; /* 3 */
	}
	/* Re-set default cursor for disabled elements. */
	button[disabled],
	html input[disabled] {cursor: default;}
	/* Remove inner padding and border in Firefox 4+.*/
	button::-moz-focus-inner,
	input::-moz-focus-inner {border: 0;padding: 0;}
	/* Address Firefox 4+ setting `line-height` on `input` using `!important` in
	 * the UA stylesheet. */
	input {line-height: normal;}
	/*------------LAYOUT------------------------*/
	/* [INPUT, BUTTON & LABEL] */
	.btn-danger,
	.login .button-primary, .login .button-primary:active, .login .button-primary:visited,
	.btn-primary, .btn-primary:visited, .btn-primary:active,
	.page-numbers, .page-numbers:visited, .page-numbers:active, .page-numbers:link {

			height: auto;
			font-family: 'lato-bold';
			font-weight: normal;
			font-size: 1rem;
			line-height: 1.5rem;
			text-transform: uppercase;
			text-align: center;
			text-decoration: none;
			border-radius: 0;
			border: none;
			padding: 10px 30px !important;
			display: inline-block !important;
			white-space: nowrap;
			vertical-align: middle;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			-webkit-transition: all .2s ease-in-out;
			-o-transition: all .2s ease-in-out;
			transition: all .2s ease-in-out;

	 }

	 .btn-danger, .btn-danger:visited, .btn-danger:active { color: $color-white; }

	.login .button-primary,
	.login .button-primary:active,
	.login .button-primary:focus,
	.login .button-primary:visited,
	.btn-primary,
	.btn-primary:visited,
	.btn-primary:active,
	.page-numbers,
	.page-numbers:visited,
	.page-numbers:active,
	.page-numbers:link {

			color: $color-white !important;
			background-color: $ljjk-black;
			border-color: $ljjk-black;

	}

	.btn-primary, .btn-primary:visited, .btn-primary:active {

		min-width: 175px;

	}

	.login .button-primary:hover,
	.btn-primary:hover, .page-numbers:hover {

		background-color: #E3001A;
		border-color: #E3001A;
		text-decoration: none;

	}
	.page-numbers.current {	background-color: #E3001A; border-color: #E3001A;	}
	.btn-yellow-red:link, .btn-yellow-red:visited { border-color: #FFEE00; background: #FFEE00; color: $ljjk-black; text-shadow: none; }
	.btn-yellow-red:hover, .btn-yellow-red:active { border-color: #E3001A; background: #E3001A; color: $color-white; }

	.input {

	    width: 100%;
	    font-size: 1rem;
	    display: inline-block;
	    position: relative;
	    color: $ljjk-black;

	}

	.labeled.input input {padding-right: 2.5em !important;}
	textarea,
	input[type="text"],
	.login input[type="text"],
	input[type="password"],
	.login input[type="password"] {

	    width: 100%;
	    margin: 0;
	    padding: 0.65em 1em;
	    font-size: 1rem;
			background: $color-white;
	    background-color: $color-white;
			border: 0.3rem solid $ljjk-black;
			border-radius: 0;
			-webkit-border-radius: 0;
	    outline: none;
	    color: $ljjk-black;
			-moz-transition: none;
      -webkit-transition: none;
      -o-transition: color 0 ease-in;
      transition: none;
			-webkit-box-shadow: none;
 			-moz-box-shadow: none;
 			box-shadow: none;
	    -webkit-appearance: none;
	    -webkit-tap-highlight-color: transparent; /* for removing the highlight */


	}
	input[type="text"],
	input[type="password"] {

			min-height: 49px !important;
			max-height: 49px !important;

  }
	textarea:focus,
	input[type="text"]:focus,
	input[type="password"]:focus {

	    color: $ljjk-black;
	    border-color: $ljjk-black;
	    border-bottom-left-radius: 0;
	    border-top-left-radius: 0;
	    -webkit-appearance: none;
	    box-shadow: 0.3em 0em 0em 0em $color-yellow inset;

	}

	textarea[readonly],
	textarea[disabled],
	input[readonly],
	input[disabled] {

	    cursor: not-allowed;
	    background-color: $color-input-bg-disable;
	    color: $color-input-tx-disable;

	}

	label, .login label {

			width: 100%;
			font-size: 1rem !important;
			color: $ljjk-black;
	    display: inline-block;
	    vertical-align: middle;
	    font-weight: normal;

	}

	p.comment-notes {

		padding:10px 0;
		font-style:italic;

	}

/*===Form contents (End)===*/

form {

		.field {

				width: 100%;
				margin-bottom: 10px;
				display: block;
				float: left;

				label, & > label {

					width: 100%;
					display: block;
					position: relative;
					margin: 0 0 3px 0;
					color: $ljjk-black;
					font-weight: normal;
					text-align: left;

				}

				.corner-label {

						position: absolute;
					  display: inline-block;
						top: 0;
				    right: 0;
						width: 2rem;
				    height: 2rem;
						z-index: 0;
				    margin: 0;
						padding: 2px 1px 0 0;
						text-align: center;
				    vertical-align: middle;
				    background-color: $ljjk-black;
						border-color: $ljjk-black;
				    color: $color-white;
				    text-transform: uppercase;
				    font-weight: normal;
				    -webkit-transition: background 0.1s linear;
				    transition: background 0.1s linear;
				    overflow: hidden;
				    background-color: transparent;
				    -webkit-transition: color 0.2s ease;
				    transition: color 0.2s ease;

						&:after {

								position: absolute;
								content: "";
								left: 0;
								top: 0;
								z-index: -1;
								width: 0em;
								height: 0em;
								border-top: 0rem solid transparent;
								border-right: 2rem solid transparent;
								border-bottom: 2rem solid transparent;
								border-left: 0rem solid transparent;
								border-right-color: inherit;
								-webkit-transition: border-color 0.2s ease;
								transition: border-color 0.2s ease;

						}

						i.icon {

							   width: 100%;
								 height: 100%;
								 font-size: 1.25rem;
								 font-style: normal;
								 font-weight: normal;
								 display: inline-block;
								 line-height: 1;
								 text-decoration: inherit;
								 text-align: right;
								 vertical-align: middle;
								 padding: 4px 4px 0 0;
								 speak: none;
								 -webkit-font-smoothing: antialiased;
								 -moz-font-smoothing: antialiased;
								 font-smoothing: antialiased;

						}
				}

				.error-corner-label {
				    border-color: $color-red;
				    color: $color-white;
				}

				.error-color {
				    color: $color-red !important;
				    border-color: $color-red !important;
				}

				.err-wrapper {

						display: none;

						.error {
						    position: relative;
								color: $color-white;
						    background: $color-red;
								border: 1px solid $color-red;
						    font-size: 1rem;
								line-height: normal;
								text-align: center;
								padding: 10px;
						    margin: 7px 0 0 0;
								border-radius: 5px;

								&::after {

								    content: " ";
								    position: absolute;
								    bottom: 100%;
								    left: 50%;
								    margin-left: -10px;
								    pointer-events: none;
								    width: 0px;
								    height: 0px;
								    border-bottom: 7px solid $color-red;
								    border-left: 7px solid transparent;
								    border-right: 7px solid transparent;

								}
						}

				}

			.input-field {

					width: 100%;
					font-size: 1em;
					display: block;
					position: relative;
					color: $ljjk-black;

			}

			.labeled {

					input { padding-right: 2.5em !important; }

			}

		} /* class field */

		.label-extra-info {

				font-size: 1rem;
				color: $ljjk-black;
		    display: block;

		}

		.form-img-loading {

		   display: none;

		}

		.feedback {

				 display: none;
				 text-align: center;
				 width: 100%;
				 float: left;
				 padding: 15px;
				 border-radius: 5px;
				 margin: 0 0 10px 0;
				 min-height: 30px;
				 background-color: $color-green;
				 color: $color-white;
				 font-size: 0.875em;

		}

		.some-errors, .tips {

				display: none;
		    width: 100%;
				float :left;
				padding: 15px;
				margin: 0 0 15px 0;
		    background: $color-light-yellow;
		    border: 1px solid $color-shadow-yellow;
				border-radius: 5px;

		    span {

		        width: 30px;
		        font-size: 2rem;
		        margin-right: 10px;
		        display: inline-block;
		        vertical-align: middle;

		    }

		    p {

			      display: inline-block;
			      width: calc( 100% - 45px );
			      vertical-align: middle;
			      text-align: left;
			      margin: 0;
			      font-size: 1rem;

		    }
		}
}
