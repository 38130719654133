.team-section {
    .col {

        justify-content: center;
        align-items: center;
        display: flex;
        text-align: center;

        figure {

            max-width: 200px;
            min-width: 200px;
            img {
                border-radius: 50%;
                border: unset;
                width: 190px;
                max-width: 190px;
                min-width: 190px;

            }
            figcaption {

                margin: 10px 0 0 0;
                color: $ljjk-black;
                background: none;

                h5 {
                    font-size: 17px;
                }
                h6 {
                    font-size: 14px;
                }

                p {
                    font-size: 14px;
                    margin: 0 0 5px 0;
                    line-height: normal;
                }
            }
        }
    }
}
