.row-news {
    margin: 0 0 20px 0;
    .col-12 {
        display: flex;
        justify-content: center;
        padding: 12px 12px 0 0 !important;
    }
}

.post-news {

    width: 300px;
    padding: 0;
    background: initial;
    height: 100%;
    margin: 0;

    a { color: $ljjk-black }

    &:hover .post-news-content-wrapper .post-news-thumbnail .thumbnail-wrapper {
      opacity: 0.8;
      transform: scale(1.02)
    }
    &:hover .post-news-content-wrapper .post-news-content .post-news-content-details,
    &:hover .post-news-content-wrapper .post-news-content h1,
    &:hover .post-news-content-wrapper .post-news-content .post-news-content-excerpt {
      opacity: 0.7;
    }

    .post-news-content-wrapper {

        display: flex;
        flex-direction: column;
        height: 100%;

        .post-news-thumbnail {

            height: 200px;
            width: 100%;
            overflow: hidden;
            border: 1px solid $color-border-right;
            border-color: $color-border-top $color-border-right $color-border-bottom;

            .thumbnail-wrapper {

                  overflow: hidden;
                  width: 100%;
                  height: 100%;
                  z-index: 0;
                  opacity: 1;
                  line-height: 0;
                  transform: scale(1);
                  transition: all .2s ease .1s;


                  img {

                      width: 100%;
                      height: 100%;
                      object-fit: cover;

                  }
            }
        }

        .post-news-content {

              position: relative;
              z-index: 1000;
              margin: -40px 0 0 12px;
              background: white;
              padding: 10px;
              border-width: 1px;
              border-style: solid;
              border-color: $color-border-top $color-border-right $color-border-bottom $color-border-left;
              display: flex;
              flex-direction: column;
              flex: 1 1 auto !important; /* IE11-fix */

              h1 {
                   font-size: 1.1rem;
                   text-transform: none;
                   text-align: left;
                   margin: 5px 0 10px 0;
              }

              .post-news-content-details {

                  font-size: .8rem;
                  text-align: left;
                  margin: 0;
                  padding: 5px 0;
                  width: auto;
                  display: flex;
                  align-items: stretch;
                  flex-direction: row;

                  span {

                      margin: 0 3px 0 0;
                      line-height: 1.1rem;

                  }
              }

              .post-news-content-excerpt {
                  font-size: .9rem;
                  text-align: left;
                  p { margin: 0;}
              }
        }
    }
}
@media screen and ( max-width:414px ) {

  .row-news {

      margin: 0 0 20px 0;

      .col-12 {

          padding: 12px 0 0 0 !important;

      }
  }

  .post-news {
      width: 95%;
  }

}
