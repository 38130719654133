.prices {
    .container {

        .rad-text {
            color: $color-white; 
        }
        .row {

              min-height: 180px;
              margin-bottom: 20px;

              .col-12 {

                  min-height: 180px;

                  h2 {

                      display: block;
                      font-size: 1rem;
                      line-height: 1rem;
                      color: $color-yellow;
                      background: $ljjk-black;
                      padding: 12px 0 6px 0;
                      margin-bottom: 0;
                      text-transform: uppercase;

                  }

                  .price { font-size: 1.3rem; font-family: lato-bold; vertical-align: middle;}
                  .price-small { font-size: 1.1rem; font-family: lato-bold; vertical-align: middle;}
                  .title { line-height: 1.3rem; vertical-align: middle;}

                  .table-wrapper {

                  	  display: table;
                  		height: 180px;
                      color: $ljjk-black;
                  		background: #e6e6e6;
                  		border: 0.3rem solid $ljjk-black;
                  		width:100%;
                      text-shadow: none;

                      .table-content  {
                      		display: table-cell;
                      		vertical-align: middle !important;
                      }

                  }


              }


        }

    }
}

@media screen and ( max-width:768px ) {
  .prices {

      background-attachment: initial;
      .container {
          .row {

          }
      }
  }
}
