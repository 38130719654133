.table-blackbelt {

    	width: 100%;
    	border: 1px solid #ccc;
    	border-spacing: 0px;
    	border-collapse: separate;

      tr {

          td {

              &:first-of-type { width: 200px;}

              p {
                  text-align: center;
                  font-size: 14px;
              }

              ul {

                  margin: 0;
                  padding: 0;
                  list-style-position: inside;

                  li {
                      font-size: 14px;
                  }
              }

              figure {

                	width: 120px;
                	height: auto;
                	margin: 0 auto;
                	display:block;

                  img {

                  		width: 100%;
                  		border: 3px solid #000;
                  		margin-bottom: 10px;

                  }

                  figcaption {

                  		font-size: .8rem;
                  		font-weight: bold;
                  		background: none;
                  		color: $ljjk-black;
                  		float: none;
                  		padding: 0;

                  }

              }
          }
      }

}
